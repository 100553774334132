import { errorBuilder } from '../errorBuilder/errorBuilder'
import { logBuilder } from '../logBuilder/logBuilder'
import * as rules from '../rules'
import * as T_VALIDATION from '../globals'

const handleCoxOnly = (type, payload) => {
	const {
		integrationId,
		urlParams,
		integrationList,
		integrationUrl
	} = payload
	const isValid = rules.onlyCoxIntegration(
		integrationId,
		urlParams,
		integrationList
	)
	if (!isValid) {
		logBuilder(
			type,
			{
				integrationId,
				integrationUrl
			}
		)
		errorBuilder(type)
	}
}
const handleDebugThirdParty = (type, payload) => {
	const {
		debugMode,
		integrationId,
		integrationUrl,
		urlParams
	} = payload
	const isValid = rules.thirdPartyServiceDebug(
		urlParams,
		debugMode
	)
	if (!isValid) {
		logBuilder(
			type,
			{
				integrationId,
				integrationUrl
			}
		)
		errorBuilder(type)
	}
}
const handleDeviceEnablement = (type, payload) => {
	const {
		wiseIntegration,
		layoutType,
		enabledSeamless,
		enabledDesktop,
		integrationId
	} = payload
	
	const isValid = rules.deviceEnablement(
		wiseIntegration,
		layoutType,
		enabledSeamless,
		enabledDesktop
	)

	if(!isValid) {
		logBuilder(
			type,
			{ integrationId }
		)
		errorBuilder(type)
	}
}
const handleDisableThirdParty = (type, payload) => {
	const {
		integrationId,
		urlParams
	} = payload
	const isValid = rules.disableThirdParty(
		integrationId,
		urlParams
	)
	if (!isValid) {
		logBuilder(
			type,
			{ integrationId }
		)
		errorBuilder(type)
	}
}
const handleEnableThirdParty = (type, payload) => {
	const {
		integrationId,
		urlParams
	} = payload
	const isValid = rules.enableThirdParty(
		integrationId,
		urlParams
	)
	if(!isValid) {
		logBuilder(
			type,
			{
				integrationId
			}
		)
		errorBuilder(type)
	}
}
const handleInvalidProtocol = (type, payload) => {
	const {
		integrationId,
		integrationUrl
	} = payload
	const isValid = rules.protocol(integrationUrl)
	if (!isValid) {
		logBuilder(
			type,
			{
				integrationId
			}
		)
		errorBuilder(type)
	}
}
const handleResetCustom = (type, payload) => {
	const {
		integrationId,
		integrationUrl,
		urlParams
	} = payload
	const isValid = rules.resetCustom(urlParams)
	if (!isValid) {
		logBuilder(
			type,
			{
				integrationId,
				integrationUrl
			}
		)
		errorBuilder(type)
	}
}

export class Validator {

	/**
	 * @param { boolean } debugMode 
	 * @param { boolean } enabledDesktop 
	 * @param { boolean } enabledSeamless 
	 * @param { string } integrationId 
	 * @param { string } integrationUrl 
	 * @param { string[] } integrationList 
	 * @param { 'desktop'|'mobile' } layoutType 
	 * @param { Object<string,string>} urlParams 
	 * @param { boolean } wiseIntegration 
	 */
	constructor(
		debugMode,
		enabledDesktop,
		enabledSeamless,
		integrationId,
		integrationUrl,
		integrationList,
		layoutType,
		urlParams,
		wiseIntegration
	) {
		this.debugMode = debugMode
		this.enabledDesktop = enabledDesktop
		this.enabledSeamless = enabledSeamless
		this.integrationId = integrationId
		this.integrationUrl = integrationUrl
		this.integrationList = integrationList
		this.layoutType = layoutType
		this.urlParams = urlParams
		this.wiseIntegration = wiseIntegration
	}

	/**
	 * @param { string } type 
	 * @returns 
	 */
	reducer(type){
		switch (type) {
			case T_VALIDATION.COX_ONLY: {
				handleCoxOnly(type, {
					integrationId: this.integrationId,
					urlParams: this.urlParams,
					integrationList: this.integrationList,
					integrationUrl: this.integrationUrl
				})
				break;
			}
			case T_VALIDATION.DEBUG_THIRD_PARTY: {
				handleDebugThirdParty(type, {
					debugMode: this.debugMode,
					integrationId: this.integrationId,
					integrationUrl: this.integrationUrl,
					urlParams: this.urlParams
				})
				break;
			}
			case T_VALIDATION.DEVICE_ENABLEMENT: {
				handleDeviceEnablement(type, {
					wiseIntegration: this.wiseIntegration,
					layoutType: this.layoutType,
					enabledSeamless: this.enabledSeamless,
					enabledDesktop: this.enabledDesktop,
					integrationId: this.integrationId
				})
				break;
			}
			case T_VALIDATION.DISABLE_THIRD_PARTY: {
				handleDisableThirdParty(type, {
					integrationId: this.integrationId,
					urlParams: this.urlParams
				})
				break;
			}
			case T_VALIDATION.ENABLE_THIRD_PARTY: {
				handleEnableThirdParty(type, {
					integrationId: this.integrationId,
					urlParams: this.urlParams
				})
				break;
			}
			case T_VALIDATION.INVALID_PROTOCOL: {
				handleInvalidProtocol(type, {
					integrationId: this.integrationId,
					integrationUrl: this.integrationUrl
				})
				break;
			}
			case T_VALIDATION.RESET_CUSTOM: {
				handleResetCustom(type, {
					integrationId: this.integrationId,
					integrationUrl: this.integrationUrl,
					urlParams: this.urlParams
				})
				break;
			}
			default:
				break;
		}
	}

	/**
	 * Validates an Integration against all validation rules
	 */
	all() {
		this.reducer(T_VALIDATION.DEVICE_ENABLEMENT)
		this.reducer(T_VALIDATION.DISABLE_THIRD_PARTY)
		this.reducer(T_VALIDATION.ENABLE_THIRD_PARTY)
		this.reducer(T_VALIDATION.INVALID_PROTOCOL)
		this.reducer(T_VALIDATION.COX_ONLY)
		this.reducer(T_VALIDATION.RESET_CUSTOM)
		this.reducer(T_VALIDATION.DEBUG_THIRD_PARTY)
	}

	/**
	 * Validates a rule
	 * 
	 * @param { string } type 
	 */
	validate(type) {
		this.reducer(type)
	}

	/**
	 * Validate a list of rules
	 * 
	 * @param { string[] } types
	 */
	validateList(types) {
		types.forEach(type => {
			this.validate(type)
		})
	}
}