import { log } from '../log';

// Convert a string of markup to actual DOM elements.
const stringToElements = inputHtml => {
	const result = document.createElement('template');
	const html = inputHtml.trim();
	result.innerHTML = html;
	return result.content.firstChild;
};

export const createMenuMarkup = data => {
	const {
		primaryText,
		secondaryText,
		expanded,
		menuIcon,
		subItems,
		position,
		onclick,
		isFragmentNavType = true
	} = data;
	
	const navType = isFragmentNavType ? 'fragment' : 'header';

	if (!primaryText) {
		log('Required primaryText field is missing.');
		return false;
	}

	// If it's a single menu item, return the simple markup early.
	if (!subItems || subItems.length === 0) {
		const markup = `
			<div class="panel-heading">
				<div class="panel-title">
					<a data-navigation-id="default" class="panel-heading-link accordion-faux collapsed" href="${data.href}" data-iconpos="left">
						<i class="icon-blank"></i>
						<span>${data.primaryText}</span>
					</a>
				</div>
			</div>
		`;

		const domElements = stringToElements(markup);

		if (onclick) {
			domElements.querySelector('a').addEventListener('click', onclick);
		}

		return domElements;
	}

	const showExpandedToggle = expanded ? ' in' : '';
	const showExpandedPanel = expanded ? '' : ' collapsed';

	// If submenu items are present, render the more complicated markup instead.
	const wrapperElement = document.createElement('div');

	const secondText = secondaryText
		? `<span class='subtext'>${secondaryText}</span>`
		: '';

	// Optionally show a user icon to the left of the user name
	// when `menuIcon` is set to `true` in the payload.
	const menuIconMarkup = menuIcon
		? '<div class="ddc-span2"><i class="ddc-icon ddc-icon-mycars-login-circle ddc-icon-size-xxlarge"></i></div>'
		: '';
	const userNameSpanType = menuIcon ? 'ddc-span10' : 'ddc-span12';

	let panelHeading = stringToElements(`
		<div class="panel-heading">
			<div class="panel-title">
				<div class="row py-3">
					${menuIconMarkup}
					<div class="${userNameSpanType} pt-3 pb-2">
						<p class="m-0">
							<a data-navigation-id="default" href="#" class="no-follow panel-heading-link panel-toggle${showExpandedPanel} p-0"
							data-toggle="collapse" data-target="#ddc-fragment-accordion-nav-wiapi-${position}" aria-expanded="false"
							ui-tabs-initial-load="false" rel="nofollow">
								<i class="ddc-icon ddc-icon-size-small ddc-icon-plus"></i>
								<span class="ddc-font-size-large font-weight-bold">${primaryText}</span></a>
						</p>
						<p class="ddc-font-size-small">${secondText}</p>
					</div>
				</div>
			</div>
		</div>
	`);

	if (!isFragmentNavType) {
		const iconMarkup = menuIcon
		? '<div class="ddc-span1"><i class="ddc-icon ddc-icon-mycars-login-circle ddc-icon-size-xxlarge"></i></div>'
		: '';

		panelHeading = stringToElements(`
			<div class="panel-heading">
				<div class="panel-title">
					<a data-navigation-id="default" href="#" class="no-follow panel-heading-link panel-toggle${showExpandedPanel}"
					data-toggle="collapse" data-target="#ddc-header-accordion-nav-wiapi-${position}" aria-expanded="false"
					ui-tabs-initial-load="false" rel="nofollow">
						<div class="pt-2 pb-6">
							${iconMarkup}
							<i class="ddc-icon ddc-icon-size-small ddc-icon-plus"></i>
							<div class="${menuIcon ? 'ddc-span10' : 'ddc-span11'}">
								<span class="ddc-font-size-large font-weight-bold">${primaryText}</span>
								<p class="ddc-font-size-small">${secondText}</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		`);
	}

	// Add the panel heading to the wrapper.
	wrapperElement.appendChild(panelHeading);

	if (!(data?.secondaryText || data?.subItems)) {
		return wrapperElement;
	}

	// Create the sub menu items.
	const subItemsList = document.createElement('ul');
	subItemsList.classList.add(`ddc-mobile-${navType}-sub-nav`, 'list-unstyled');

	if (data?.subItems) {
		data.subItems.forEach(item => {
			const li = document.createElement('li');
			const anchor = document.createElement('a');
			anchor.setAttribute('data-navigation-id', 'default');
			anchor.classList.add('nav-link', 'tier3');
			anchor.href = item.href;
			anchor.textContent = item.text;

			// Attach click handler if it exists
			if (typeof item.onclick === 'function') {
				anchor.addEventListener('click', item.onclick);
			}

			li.appendChild(anchor);
			subItemsList.appendChild(li);
		});
	}

	// Create the wrapper for the sub menu items.
	const panelBodyWrapper = stringToElements(`
		<div id="ddc-${navType}-accordion-nav-wiapi-${position}" class="panel-collapse collapse${showExpandedToggle}" aria-expanded="true" style="">
			<div class="panel-body">
			</div>
		</div>
	`);

	// Add the sub menu items to the wrapper.
	const panelBodyTarget = panelBodyWrapper.querySelector('.panel-body');
	panelBodyTarget.appendChild(subItemsList);

	// Add the panel body wrapper to the parent wrapper.
	wrapperElement.appendChild(panelBodyWrapper);

	return wrapperElement;
};
