// import jsDoc types for IntelliSense
import '../../types/index.d';

import { getPageMetaData } from './get-page-meta-data';
import { isWIAPILoaded } from './wait-for';

// TODO: Consider making a 'WIAPI Features' or 'WIAPI Settings' tool in WISE
// where we can enable or disable platform level features based on the needs of
// integrations that are enabled or disabled.

const KEY_USER_SHOW_PAYMENTS = 'userShowPayments';
const KEY_WIAPI_SETTINGS = 'wiapiSettings';

/**
 * This specifies the config fieldName names that are treated as
 * 'settings' in the 'wiapiSettings' local storage object.
 */
const FIELDS_WIAPI_SETTINGS = ['showPayments', 'useFilterSortPlaceholders', 'delayLoadVdpFooter'];

/**
 * 	Set to the normalized value unless it's already `true`
 * If it's true, just leave it that way as another integration
 * may have set it to true.
 *
 * @param { any } value
 * @returns { any }
 */
const normalize = value => {
	if (['true', 'false'].includes(value)) {
		return value === 'true';
	}
	return value;
};

/**
 * Helper function for retrieving data from session storage.
 *
 * 1. Get WIAPI settings from session storage.
 * 2. If a `pageName` is passed, get the settings for that specific page.
 *
 * @param { ?string } pageName
 * @returns { Promise<AnyObject> }
 */
export const getSettingsFromStorage = pageName => {
	const settings = JSON.parse(sessionStorage.getItem(KEY_WIAPI_SETTINGS)) || {};

	if (pageName) {
		return settings?.[pageName] || {};
	}

	return settings;
};

/**
 * Get WIAPI configuration settings,
 *
 * 1. Wait for WIAPI to load.
 * 2. Get WIAPI settings.
 *     * If a `pageName` is passed, get the settings for that specific page.
 *
 * @param { ?string } pageName
 * @returns { Promise<AnyObject> }
 */
export const getSettings = async pageName => {
	await isWIAPILoaded();
	return getSettingsFromStorage(pageName);
};

export const getUserShowPayments = () => {
	const showPayments =
		JSON.parse(localStorage.getItem(KEY_USER_SHOW_PAYMENTS)) || {};
	return showPayments?.show;
};

/**
 * Create a NEW WIAPI settings object in session storage.
 *
 * **This overwrites the WIAPI object in storage**
 * @param { AnyObject } settings
 * @returns
 */
export const setSettings = settings => {
	return sessionStorage.setItem(KEY_WIAPI_SETTINGS, JSON.stringify(settings));
};

/**
 * Update stored WIAPI settings.
 *
 * 1. GET: WIAPI settings from session storage.
 * 2. UPDATE: WIAPI settings with new values.
 * 3. SET: WIAPI settings in session storage.
 *
 * @param { string } name
 * @param { any } value
 * @param { boolean } force
 * @returns { void }
 */
export const addToSettings = async (name, value, force = false) => {
	const pageData = await getPageMetaData();
	const { pageName } = pageData;

	if (!pageName) {
		return;
	}
	const settings = getSettingsFromStorage();
	const normalizedValue = normalize(value);

	if (!settings[pageName]) {
		settings[pageName] = {};
	}
	if (
		normalizedValue !== undefined &&
		(force || settings[pageName][name] !== true)
	) {
		settings[pageName][name] = normalizedValue;
		setSettings(settings);
	}
};

/**
 * Specify field names on integrations that are then copied into the 'wiapiSettings' local storage
 * as 'features' available to other widgets and parts of the system to reference when rendering.
 *
 * Example use case:
 *
 * On the SRP, the layout of the filters/sort area changes when certain integrations are
 * loaded and need placements in this area to accommodate their needs. If these integrations
 * are not loaded, a different layout is required. This functionality allows a field in the integration's
 * config to inform the SRP widgets in a loosely coupled manner about the features the WIAPI is expecting
 * to have available, to dictate how the SRP layout and WIAPI placeholder options are presented.
 *
 * @param { AnyObject[] } fields
 * @returns { true }
 */
export const updateSettingsFromConfig = fields => {
	FIELDS_WIAPI_SETTINGS.forEach(async settingName => {
		if (fields[settingName] !== undefined) {
			addToSettings(settingName, fields[settingName]);
		}
	});

	return true;
};
