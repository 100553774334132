import { getPageMetaData } from './get-page-meta-data';
import { fetchJson } from './helpers';

const itemListPromises = {};

export const getItemList = async (init, type) => {
	const pageData = await getPageMetaData();

	const allowedIntegrations = ['item-list-badges'];
	const allowedTypes = ['inventory-badges', 'inventory-media'];

	if (!allowedTypes.includes(type)) {
		throw new Error(
			`The requested item list ${type} is not enabled for this API.`
		);
	}

	if (!allowedIntegrations.includes(init.integrationId)) {
		throw new Error(
			'The getItemList method is not allowed for use with this integration.'
		);
	}

	if (!(pageData.searchPage || pageData.detailPage)) {
		return [];
	}

	if (!itemListPromises[type]) {
		itemListPromises[type] = fetchJson(
			init,
			`/api/badge-item-lists/${type}`,
			'getItemList'
		);
	}

	return itemListPromises[type];
};
