import { applyButtonOverrides } from '../create/button-markup';
import { activateModules } from '../utils/helpers';
import {
	validateIfLocationIsModifiable,
	validateInitIsAnObject,
	validateTheModifierObjectForLocation
} from '../utils/validator';
import { modifyLocations } from './locations';
import { setPageLevelModification } from './osirisContentModifications';

export const createModifier = (init, location, modifierObject) => {
	validateInitIsAnObject(init, 'init');
	validateIfLocationIsModifiable(location);
	validateTheModifierObjectForLocation(location, modifierObject);

	// Update the Private API and trigger a custom event with page level changes
	// so that individual Osiris widgets can handle relevant updates.
	setPageLevelModification(init.integrationId, location, modifierObject);

	const { schema, callback } = modifierObject;
	const locationHandler = modifyLocations[location];

	const apply = async () => {
		await locationHandler.isReady();
		const locationElems = await locationHandler.getElements();

		if (!locationElems) {
			return;
		}

		locationElems.forEach(locationElem => {
			switch (location) {
				case 'schedule-service': {
					const newButtonMarkup = applyButtonOverrides(
						{ init, location, schema, integrationCall: callback },
						locationElem,
						schema,
						false /* Not a vehicle cta */
					);
					activateModules(newButtonMarkup);
					break;
				}

				default:
					break;
			}
		});
	}

	return { apply };
}
