import { deepCopy } from '../utils/copy';
import { trackAPIMethods } from '../tracking';
import { MapOfStringToList } from '../utils/map-of-string-to-list';
import { Subscription } from './subscription';
import { log } from '../log';
import {
	validateInitIsAnObject,
	validateNonZeroLengthString
} from '../utils/validator';

const events = new MapOfStringToList();
const subscriptions = new MapOfStringToList();

const callCallback = (integrationId, callback, ev) => {
	try {
		callback(deepCopy(ev));
	} catch (e) {
		log(`Callback failed: ${integrationId} ${JSON.stringify(ev)}.`, e);
	}
};

export const fireEvent = ev => {
	if (!ev.type) {
		throw new Error('Events must have a type property.');
	}

	const modifiedEvent = {
		...ev,
		timestamp: new Date()
	};

	subscriptions.forEach(ev.type, subscription => {
		callCallback(
			subscription.init.integrationId,
			subscription.callback,
			modifiedEvent
		);
	});

	events.push(ev.type, modifiedEvent);
};

export const subscribe = (init, eventType, callback) => {
	validateInitIsAnObject(init, 'init');
	validateNonZeroLengthString(eventType, 'eventType');

	const subscription = new Subscription(init, callback);

	subscriptions.push(eventType, subscription);

	events.forEach(eventType, ev => {
		callCallback(init.integrationId, callback, ev);
	});

	trackAPIMethods(init, { subscribedEvent: eventType });
};

export const clear = () => {
	events.clear();
	subscriptions.clear();
};
