import { EventEmitter } from './event-emitter';

export class InvokeOnceEventEmitter extends EventEmitter {
	constructor(eventType) {
		super(eventType);

		this.invokeOnce = this.invokeOnce.bind(this);

		this.invoked = false;
	}

	invoke(data) {
		if (!this.invoked) {
			this.invokeOnce(data);
			this.invoked = true;
		}
	}
}
