import { VehicleLocation } from './vehicle-location';

const badgeClassesPrefix = 'vehicle-card-badge badge hidden';

export class BadgeLocation extends VehicleLocation {
	async getConfig() {
		return {
			classes: `${badgeClassesPrefix} m-3 p-0`,
			elementType: 'li',
			gridSrpClasses: `${badgeClassesPrefix} align-center m-0 p-3`,
			responsiveSrpClasses: `${badgeClassesPrefix} m-2 py-3`,
			vdpClasses: `${badgeClassesPrefix} align-center`
		};
	}
}
