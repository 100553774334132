/**
 * Validate url protocol.
 * 
 * Fails if the URL does not start with `https://` or `/`
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param { string } integrationUrl
 * @returns { boolean } 
 */
export const protocol = (integrationUrl) => {
    if (
        !integrationUrl.startsWith('https://') &&
        !integrationUrl.startsWith('/')
    ) {
        return false
    }
    return true
}