import { addToSettings, getSettings, getUserShowPayments } from './settings';

const showOrHidePayments = action => {
	// Remove any payments already displayed on the page.
	const target = document.querySelector('.ws-inv-listing');
	if (target) {
		target
			.querySelectorAll('.loan-payment, .lease-payment')
			.forEach(payment => {
				if (action === 'hide') {
					payment.classList.add('d-none');
				} else if (action === 'show') {
					payment.classList.remove('d-none');
				}
			});
	}

	const facets = document.querySelectorAll('#paymentLoan, #paymentLease');
	if (facets) {
		facets.forEach(facet => {
			if (action === 'hide') {
				facet.classList.add('d-none');
			} else if (action === 'show') {
				facet.classList.remove('d-none');
			}
		});
	}
};

export const hideDefaultPayments = () => {
	// Prevent new payments from being displayed as vehicle cards are rendered.
	addToSettings('hideDefaultPayments', true);
	showOrHidePayments('hide');
};

export const showDefaultPayments = () => {
	addToSettings('hideDefaultPayments', false, true);
	showOrHidePayments('show');
};

/**
 *
 * @returns { Promise<void> }
 */
export const handlePaymentVisibility = async () => {
	const pageName = window?.DDC?.siteSettings?.pageAlias;
	if (!pageName) {
		return;
	}
	const pageSettings = await getSettings(pageName);
	const showPayments = getUserShowPayments();

	if (pageSettings?.hideDefaultPayments || showPayments) {
		hideDefaultPayments();
	} else {
		showDefaultPayments();
	}
};
