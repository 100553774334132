// import jsDoc types for IntelliSense
import '../../types/editor.d';
import '../../types/index.d';
import '../../types/button-markup.d'

import { getLocalizedContent, isNewSrp } from '../utils/helpers';
import { allowSelectedUpdatesToButtonClasses } from '../edit/locations/index';

const newSrp = isNewSrp();

/**
 * @param { Element } el 
 * @param { AnyObject } attributes 
 * @returns { Element }
 */
export const addAttributes = (el, attributes) => {
	if (typeof attributes === 'object') {
		Object.keys(attributes).forEach(attribute => {
			if (attribute) {
				el.setAttribute(attribute, attributes[attribute]);
			}
		});
	}
	return el;
};

/**
 * @param { Instance } instance 
 * @param { Element } el 
 * @param { InsertLocations } location 
 * @returns { Element }
 */
export const applyButtonWrapperOverrides = (
	instance,
	el,
	location
) => {
	el.classList.remove('hidden');

	// Set attributes on the element to make debugging easier.
	if (instance.init && instance.init.integrationId) {
		el.setAttribute('data-modified-by', instance.init.integrationId);
	}
	el.setAttribute('data-content-source', 'Web Integration API');

	if (instance.targetLocation) {
		el.setAttribute('data-content-intent', instance.targetLocation);
	}

	if (instance.destinationLocation) {
		el.setAttribute('data-content-destination', instance.destinationLocation);
	}

	if (location) {
		el.setAttribute('data-location', location);
	}

	return el;
};

/**
 * 
 * @param { Instance } instance 
 * @param { Element } element 
 * @param { ButtonOverrides } options 
 * @param { Boolean } vehicleCTA 
 * @param { Boolean } includeWIAPIAttributes 
 * @returns 
 */
export const applyButtonOverrides = (
	instance,
	element,
	options,
	vehicleCTA = true,
	includeWIAPIAttributes = true
) => {
	const allowModifyClasses =
		!instance.destinationLocation ||
		!allowSelectedUpdatesToButtonClasses.includes(instance.destinationLocation);

	// This removes event listeners from the CTA by cloning the
	// markup and replacing the CTA with one that looks identical.
	let el = element.parentNode ? element.cloneNode(true) : element;

	if (element.parentNode) {
		element.parentNode.replaceChild(el, element);
	}

	const buttonText = getLocalizedContent(options.text);

	if (options.href) {
		el.href = options.href;

		// This is here for backwards compatability.
		// It was originally defined as 'src' and set to 'href'
		// which doesn't really make sense, but some integrations
		// still use this.
	} else if (options.src) {
		el.href = options.src;
	} else {
		el.removeAttribute('href');
		el.removeAttribute('data-href');
	}

	// Allow inline style overrides to resolve edge case issues.
	if (options.style && typeof options.style === 'string') {
		el.style = options.style.trim();
	}

	if (vehicleCTA && allowModifyClasses) {
		el.classList.add('btn', 'btn-block');

		if (newSrp) {
			el.classList.add('btn-sm');
		}

		el.classList.remove('btn-default', 'btn-primary');
	}

	if (options.imgSrc) {
		el.innerHTML = '';

		let img = document.createElement('img');

		img.src = options.imgSrc;
		img.alt = options.imgAlt ? getLocalizedContent(options.imgAlt) : buttonText;

		if (options.imgClasses) {
			img.className = options.imgClasses;
		}

		img = addAttributes(img, options.imgAttributes);

		el.appendChild(img);
	} else if (buttonText) {
		el.innerHTML = buttonText;
		el.setAttribute('alt', buttonText);

		if (vehicleCTA && allowModifyClasses) {
			if (options.type === 'primary' || options.type === 'text') {
				el.classList.add(`btn-${options.type}`);
			} else {
				el.classList.add('btn-default');
			}
		}
	}

	if (options.onclick) {
		el.addEventListener('click', options.onclick);
	}

	if (options.target) {
		el.setAttribute('target', options.target);
	}

	// Remove attributes which should not be set by default.
	// The attributes can be re-added in the next step.
	['data-width', 'data-dialog-class'].forEach(attributeName => {
		el.removeAttribute(attributeName);
	});

	el = addAttributes(el, options.attributes);

	// Remove these classes as they should be off by default
	// and can be re-added by the integration in the next code block.
	['dialog', 'hide', 'hidden'].forEach(className => {
		el.classList.remove(className);
	});

	// The above classes are re-added here in case they are missing,
	// and newly specified classes are added.
	if (options.classes && options.classes.trim() !== '') {
		options.classes
			.trim()
			.split(' ')
			.forEach(className => {
				el.classList.add(className);
			});
	}

	if (includeWIAPIAttributes) {
		el = applyButtonWrapperOverrides(instance, el);
	}

	// Popover support
	if (options.popover) {
		const popoverOptions = {
			toggle: 'popover',
			animation: options.popover.animation || true,
			container: options.popover.container || 'body',
			'delay-show': options.popover.delayShow || 0,
			'delay-hide': options.popover.delayHide || 0,
			title: getLocalizedContent(options.popover.title) || '',
			content: getLocalizedContent(options.popover.content) || '',
			html: options.popover.html || '',
			placement: options.popover.placement || 'left',
			trigger: options.popover.trigger || 'hover'
		};

		Object.keys(popoverOptions).forEach(attribute => {
			el.setAttribute(`data-${attribute}`, popoverOptions[attribute]);
		});
	}

	return el;
};
