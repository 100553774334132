import { trackAPIMethods } from './tracking';
import { getVehicleData } from './utils/get-vehicles';

const GALLERY_TARGETS = ['vehicle-media'];
const CONTENT_TYPES = ['image', 'html'];
const POSITIONS = ['primary', 'secondary', 'last'];
const INSERT_METHODS = ['insert', 'replace'];

// An immutable function that returns default properties for content['vehicle-media'][vin]
// - returns: {primary: [], secondary:[], last:[]}
const defaultMediaProps = () =>
	POSITIONS.reduce((acc, pos) => {
		acc[pos] = [];
		return acc;
	}, {});

// content store
// vehicle-media key is for storing content keyed to a vin
const content = {
	'vehicle-media': {}
};

// key storage for content items to avoid duplication
const contentKeySet = new Set();

// reset all media content
export const clearGalleryContent = () => {
	// reset the content store
	content['vehicle-media'] = {};
	// reset the key set
	contentKeySet.clear();
};

// validate the target
// - target: enum{GALLERY_TARGETS}
const validateGalleryTarget = target => {
	if (!GALLERY_TARGETS.includes(target)) {
		throw new Error(
			`Unsupported gallery: '${target}'. Expected one of: ${GALLERY_TARGETS.join(
				', '
			)}.`
		);
	}
};

// validate the type
// - type: enum{CONTENT_TYPES}
const validateContentType = type => {
	if (!CONTENT_TYPES.includes(type)) {
		throw new Error(
			`Unsupported vehicle media content type: '${type}'. Expected one of: ${CONTENT_TYPES.join(
				', '
			)}.`
		);
	}
};

// validate the insert method
// - type: enum{INSERT_METHODS}
const validateInsertMethod = insertMethod => {
	if (!INSERT_METHODS.includes(insertMethod)) {
		throw new Error(
			`Unsupported vehicle media content insertMethod: '${insertMethod}'. Expected one of: ${INSERT_METHODS.join(
				', '
			)}.`
		);
	}
};

// validate the position
// - position: enum{POSITIONS}
const validatePosition = position => {
	if (!POSITIONS.includes(position)) {
		throw new Error(
			`Unsupported vehicle media position: '${position}'. Expected one of: ${POSITIONS.join(
				', '
			)}.`
		);
	}
};

// validate for required value
// - arg: any
const validateRequiredArg = (argName, arg) => {
	// arg is required, throw an error if missing
	if (!arg) {
		throw new Error(`Unsupported request. ${argName} is a required field.`);
	}
};

// dispatch an event with data for a single vin
// - vin: string
const triggerVehicleMediaUpdatedEvent = window._.debounce(vin => {
	// build the content for a specific vin
	const detailPayload = {
		vin,
		content: content['vehicle-media'][vin]
	};

	window.dispatchEvent(
		new CustomEvent('wiapiVehicleMediaUpdated', {
			detail: detailPayload
		})
	);
}, 100);

// dispatch an event with content for "ALL" vehicles
const triggerAllVehicleMediaUpdatedEvent = window._.debounce(() => {
	window.dispatchEvent(
		new CustomEvent('wiapiAllVehiclesMediaUpdated', {
			detail: {
				content: content['vehicle-media']
			}
		})
	);
}, 100);

// insert vehicle media into the content store and trigger update events in the browser
// - dataArray: {vin: string, images: content[]}[]
// content: { type: enum{CONTENT_TYPES}, position: enum{POSITIONS}, src: string, thumbnail?: string, html?: string, trigger?: string}
const insertVehicleMedia = (dataArray, vehicleData) => {
	// Loop over dataArray
	// - data: {vin: string, images: content[]}

	dataArray.forEach(data => {
		// destructure the data object
		const { vin, images, insertMethod = 'insert' } = data;

		// validate required args
		validateRequiredArg('vin', vin);
		validateRequiredArg('images', images);
		validateInsertMethod(insertMethod);

		const vehicle = vehicleData.find(item => item.vin === vin);

		// vivification of the content['vehicle-media'][vin] object
		// If it already is defined assign it to itself else set it to the default
		content['vehicle-media'][vin] =
			content['vehicle-media'][vin] || defaultMediaProps();
		content['vehicle-media'][vin].insertMethod = insertMethod;
		content['vehicle-media'][vin].vehicle = vehicle;

		// Loop over the images array
		images.forEach(entry => {
			// destructure args from the image
			const {
				type,
				position,
				src,
				thumbnail = '',
				html,
				trigger = 'click',
				callback
			} = entry;

			// validate args
			validateContentType(type);
			validatePosition(position);

			// for 'image' or 'html' case
			if (CONTENT_TYPES.includes(type)) {
				// set a unique imageKey
				const contentKey = `${vin}${position}${src}`;
				let err = '';

				// Check if a src exists for images
				if (!src || src.trim() === '') {
					err = "Vehicle media images must have a 'src' property.";
					trackAPIMethods(init, {
						methodType: 'insertVehicleMedia',
						status: 'Failed',
						message: err
					});
					throw new Error(err);
				}
				// Check if html exists for html types
				if (
					type === 'html' &&
					(!(html || callback) || (html && html.trim() === '' && !callback))
				) {
					err = "Vehicle media HTML must have an 'html' property with content.";
					trackAPIMethods(init, {
						methodType: 'insertVehicleMedia',
						status: 'Failed',
						message: err
					});
					throw new Error(err);
				}

				// if the contentKey does not exist
				if (!contentKeySet.has(contentKey)) {
					// push new media into the content store
					content['vehicle-media'][vin][position].push({
						vin,
						type,
						src,
						thumbnail: thumbnail && thumbnail.trim() !== '' ? thumbnail : src,
						position,
						html,
						trigger,
						callback
					});
				}
				// add the contentKey to the key storage
				contentKeySet.add(contentKey);
			}
		});

		// trigger dispatching an event to update a vehicle
		triggerVehicleMediaUpdatedEvent(vin);
	});

	// trigger dispatching an event to update "ALL" vehicles
	triggerAllVehicleMediaUpdatedEvent();
};

// insert supplied content into the content store
// - init: null
// - target: enum{GALLERY_TARGETS}
// - data: {vin: string, images: image[], content: html[]}[] | {vin: string, images: image[], content: html[]}
// image: { type: enum{CONTENT_TYPES}, position: enum{POSITIONS}, src: string, thumbnail?: string}
// html: { type: enum{CONTENT_TYPES}, position: enum{POSITIONS}, content: string, image?: string, trigger: string}
export const insertGalleryContent = async (init, target, data) => {
	validateGalleryTarget(target);

	// normalize data to the correct shape
	// {vin: string, images: image[]}[]
	const normalizedData = Array.isArray(data) ? data : [data];

	if (target === 'vehicle-media') {
		const vehicleData = await getVehicleData();
		insertVehicleMedia(normalizedData, vehicleData);
	}

	trackAPIMethods(init, {
		methodType: 'insertGalleryContent',
		location: target
	});
};

// retrieve content for a single vin
// - target: enum{GALLERY_TARGETS}
// - vin: string
export const getGalleryContent = (target, vin) => {
	validateGalleryTarget(target);
	// return content for a specific vin
	return content[target][vin];
};

// retrieve all content
// - target: enum{GALLERY_TARGETS}
export const getAllGalleryContent = target => {
	validateGalleryTarget(target);
	// return all content
	return content[target];
};
