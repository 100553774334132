import { getUrlParams } from '../utils/helpers';

export const log = (id, ...args) => {
	const ident = typeof id === 'object' ? id.integrationId : id;
	if (window?.DDC?.integrationLog) {
		window?.DDC?.integrationLog(ident, ...args);
	}
};

export const isDebugMode = () => {
	const urlParams = getUrlParams();
	const debugMode = urlParams && urlParams._integrationMode === 'debug';
	return debugMode;
};

export const isTraceMode = () => {
	const urlParams = getUrlParams();
	const traceMode =
		urlParams &&
		((isDebugMode() && urlParams._integrationLogLevel === 'trace') ||
			urlParams._integrationMode === 'trace');
	return traceMode;
};

export const debugMode = isDebugMode();
export const traceMode = isTraceMode();
