/**
 * Validate thirdparty integration is enabled.
 * 
 * Disables all integrations except for a single integrationId
 * 
 * @param { string } integrationId
 * @param { { _onlyEnableThirdParty?: string }}
 * @returns { boolean }
 */
export const enableThirdParty = (integrationId, urlParams) => {
    if (
        urlParams._onlyEnableThirdParty &&
        urlParams._onlyEnableThirdParty !== integrationId
    ) {
        return false
    }
    return true
}