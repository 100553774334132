/**
 * Validate thirdparty integration is not disabled with URL params.
 * 
 * Disables a single integrationId
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param { string } integrationId
 * @param { { _onlyDisableThirdParty?: string } } urlParams
 * @returns { boolean } 
 */
export const disableThirdParty = (integrationId, urlParams) => {
    if(
        urlParams._onlyDisableThirdParty &&
        urlParams._onlyDisableThirdParty === integrationId
    ) {
            return false
    }
    return true
}