import { InvokeOnceEventEmitter } from '../invoke-once-event-emitter';
import { getPageMetaData } from '../../utils/get-page-meta-data';

import { invokeStandardInserts, invokeStandardUpdates } from '../../edit';

export class PageLoadEventEmitter extends InvokeOnceEventEmitter {
	constructor() {
		super('page-load-v1');
	}

	async invokeOnce() {
		const pageData = await getPageMetaData();
		this.fireEvent({ payload: pageData });
		invokeStandardInserts({ exclude: 'listings-' });
		invokeStandardUpdates();
	}
}
