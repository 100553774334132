import { Location } from '../../location';
import { getPageMetaData } from '../../utils/get-page-meta-data';

export class HeaderToolbarLocation extends Location {
	async getConfig() {
		const pageData = await getPageMetaData();
		const config = {
			elementType: 'div'
		};

		const isCenteredNav = document.querySelector('.responsive-centered-nav');

		if (pageData.layoutType === 'desktop') {
			config.classes =
				'd-flex flex-column justify-content-center align-items-end navbar';

			if (isCenteredNav) {
				config.classes = `${config.classes} m-4`;
			}
		}
		return config;
	}

	prepareLocation() {
		/**
		 * Adjust the parent class on the navbar to accommodate new locations.
		 * - First template-navbar widget on screen
		 */
		const navbar = document.querySelector(`
			[data-widget-id="template-navbar1"] .navbar-inner
		`);

		if (!navbar) {
			return;
		}

		/**
		 * Default string for navbar item count without the count<number> suffix
		 */
		const navbarStr = 'navbar-inner-count-';

		/**
		 * List of Links and Search buttons in the navbar.
		 * - ddc-button-nav links
		 * - div containing ddc-btn-wiapi insert location
		 */
		const itemListAll = navbar.querySelectorAll(`
			.ddc-btn-nav,
			div.ddc-btn-wiapi
		`);

		// Replace navbar inner count
		// 1. Remove old count class
		navbar.className = navbar.className.replace(new RegExp(/navbar-inner-count-\d/, 'g'), '');
		// 2. Add new count class
		navbar.classList.add(`${navbarStr}${itemListAll.length.toString()}`);
	}

	getMeta() {
		return getPageMetaData();
	}
}
