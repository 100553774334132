import { invokeLoaders } from './index';

window.addEventListener('DOMContentLoaded', () => {
	// Load all items (CSS and JS) as soon as the content loaded event occurs.
	// This is to avoid unnecessarily delaying API integrations behind non-API integrations.
	// When we eventually have a quarum of users on the API, we should switch this to only
	// load stylesheets here and load 'all' at the load event instead.
	invokeLoaders('css'); // Load CSS early to avoid repainting
	invokeLoaders('js'); // Load JS early to avoid unnecessarily delaying integrations at this stage.
});

window.addEventListener('load', () => {
	invokeLoaders('all'); // Load remaining items after load event
});
