const modifications = {};

/**
 *
 * @param {string} location - The location where the content needs to be modified
 *
 * The custom wiapiPageModificationUpdate event gets used by osiris widgets like
 * ws-content-cta to modify it's cta based on the schema which gets passed by
 * the web integration. This event is fired only after the wiapi validates the schema
 * and makes sure that a given location is not already modified by a different WI
 */
const triggerPageModificationsUpdateEvent = location =>
	window._.debounce(() => {
		window.dispatchEvent(
			new CustomEvent('wiapiPageModificationUpdate', {
				detail: {
					location
				}
			})
		);
	}, 100);

const validateIfLocationIsNotAlreadyModified = location => {
	if (!location) {
		throw new Error('Location is required to set a modification.');
	}
	if (modifications[location]) {
		throw new Error(
			`The location '${location}' has already been modified by integration '${modifications[location].integration}'.`
		);
	}
};

export const setPageLevelModification = (
	integration,
	location,
	modifierObject
) => {
	validateIfLocationIsNotAlreadyModified(location);

	modifications[location] = { integration, ...modifierObject };
	triggerPageModificationsUpdateEvent(location)();
};

export const getPageLevelModifications = location =>
	!location ? modifications : modifications[location];
