export class MapOfStringToList {
	constructor() {
		this.data = {};

		this.push = this.push.bind(this);
		this.forEach = this.forEach.bind(this);
		this.ensureListForKey = this.ensureListForKey.bind(this);
		this.clear = this.clear.bind(this);
	}

	push(key, ...args) {
		this.ensureListForKey(key).push(...args);
	}

	forEach(key, ...args) {
		this.ensureListForKey(key).forEach(...args);
	}

	ensureListForKey(key) {
		if (this.data[key] === undefined) {
			this.data[key] = [];
		}

		return this.data[key];
	}

	clear() {
		this.data = {};
	}
}
