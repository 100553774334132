export const normalizeIntegrationId = id => {
	let normalizedId = id;
	normalizedId = normalizedId.replace(/^(tps-)/, '');
	normalizedId = normalizedId.replace(/^(cst-parse-)/, '');
	normalizedId = normalizedId.replace(/^(cst-)/, '');
	normalizedId = normalizedId.replace(/^(cw-)/, '');
	return normalizedId;
};

export const normalizeIntegrationType = id => {
	if (!id) {
		return 'Undefined';
	}

	const integrationTypes = [
		'Web Integration API',
		'Third Party Services API Full',
		'Third Party Services API Compatible',
		'Third Party Services API Hybrid',
		'Third Party Services Velocity',
		'Custom Work (cms-sites)'
	];

	// If the ID has already been normalized, just return it.
	if (integrationTypes.includes(id)) {
		return id;
	}

	if (id.toLowerCase() === 'wise') {
		return integrationTypes[0];
	} else if (id.toLowerCase() === 'api') {
		return integrationTypes[1];
	} else if (id.toLowerCase() === 'api_compatible') {
		return integrationTypes[2];
	} else if (id.toLowerCase() === 'hybrid' || id.startsWith('tps')) {
		return integrationTypes[3];
	} else if (id.toLowerCase() === 'legacy') {
		return integrationTypes[4];
	} else if (
		id.toLowerCase() === 'custom' ||
		id.startsWith('cw') ||
		id.startsWith('cst')
	) {
		return integrationTypes[5];
	}

	return 'Undefined';
};
