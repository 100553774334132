import { trackAPIMethods, trackClick } from '../tracking';
import { activateModules, getHash } from './helpers';
import { eventOptions } from './config';
import { log } from '../log';

const parentTargets = [
	'[data-location]',
	'.ws-tps-placeholder',
	'.ddc-content'
];

export const append = (init, targetEl, appendEl) => {
	// Make external links have rel="nofollow"
	const appendLinks = appendEl.querySelectorAll
		? appendEl.querySelectorAll('a')
		: [];
	[appendEl, ...appendLinks].forEach(link => {
		const href = link.getAttribute ? link.getAttribute('href') : link.href;
		if (href && (!href.startsWith('/') || href.startsWith('//'))) {
			link.setAttribute('rel', 'nofollow');
		}
	});

	const hashedContents = getHash(appendEl.outerHTML);

	const existingInsert = targetEl
		.closest('[data-location]')
		.querySelectorAll(`[data-web-api-unique-hash="${hashedContents}"]`);

	const alreadyInserted = existingInsert.length > 0;

	if (alreadyInserted) {
		log(`Already inserted content for ${init.integrationId}. Skipping.`);
		return existingInsert;
	}

	appendEl.setAttribute('data-web-api-unique-hash', hashedContents);
	appendEl.setAttribute('data-web-api-inserted', true);

	// Track user clicks on inserted content
	if (appendEl.addEventListener) {
		appendEl.addEventListener(
			'click',
			data => {
				trackClick(init, data);
			},
			eventOptions
		);
	}

	// Append the desired content to the target location.
	targetEl.appendChild(appendEl);

	if (targetEl.classList) {
		targetEl.classList.remove('hide', 'hidden');
	}

	// Activate DDC JavaScript modules which may be relevant to this element.
	activateModules(targetEl);

	parentTargets.forEach(parentContainer => {
		const parent = targetEl.closest(parentContainer);

		if (!parent) {
			return;
		}

		// Center primary-banner images
		if (parent.getAttribute('data-location') === 'primary-banner') {
			if (appendEl.querySelector('img')) {
				appendEl.querySelector('img').classList.add('align-center');
			}
		}

		// Clear the contents of the media container before inserting new contents
		if (
			parent.outerHTML &&
			parent.outerHTML.includes('vehicle-media-container')
		) {
			parent.innerHTML = '';
			parent.appendChild(targetEl);
		}

		// Un-hide the container this is inside of so we can see the appended contents.
		parent.classList.remove('hide', 'hidden');
	});

	trackAPIMethods(init, {
		methodType: 'Append',
		appendElement: appendEl.tagName
	});

	return targetEl;
};
