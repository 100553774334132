/**
 * Validate an integration is enabled on the device type.
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param { boolean } isWiseIntegration 
 * @param { 'mobile' | 'desktop' } layoutType 
 * @param { boolean } isEnabledSeamless 
 * @param { boolean } isEnabledDesktop 
 * @returns { boolean }
 */
export const deviceEnablement = (
    isWiseIntegration,
    layoutType,
    isEnabledSeamless,
    isEnabledDesktop
) => {
    /*
    * All integrations created in WISE would return a config obj only
    * when they are enabled on a given page. So, we can skip the device
    * type enablement check for them.
    */
    if (!isWiseIntegration) {
        switch (layoutType) {
            case 'mobile': return isEnabledSeamless
            case 'desktop': return isEnabledDesktop
            default:
                return false
        }
    }
    return true
}