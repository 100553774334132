import { getAttributeForVehicles } from './get-vehicles';
import { trackAPIMethods } from '../tracking';

const decodeJwt = token => {
	const splitToken = token.split('.');
	if (splitToken.length < 2) {
		return;
	}
	const base64Url = splitToken[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); // eslint-disable-line prefer-template
			})
			.join('')
	);

	return JSON.parse(jsonPayload); // eslint-disable-line consistent-return
};

const getJwt = async (init, jwtMethod) => {
	const vins =
		jwtMethod === 'getJwtForVehicles'
			? await getAttributeForVehicles(init, 'vin')
			: ['false'];

	return fetch(`/api/ipp/jwt/vehicles?vins=${vins.join()}`).then(response => {
		if (!response.ok) {
			trackAPIMethods(init, {
				methodType: jwtMethod,
				status: 'Failed'
			});
			throw new Error('Invalid response from the JWT endpoint.');
		}
		return response.text().then(result => {
			const decodedJwt = decodeJwt(result);

			trackAPIMethods(init, {
				methodType: jwtMethod,
				status: 'Success'
			});

			if (jwtMethod === 'getJwtForVehicles') {
				return {
					vins: decodedJwt.VINs,
					jwt: result
				};
			}

			return {
				jwt: result
			};
		});
	});
};

export const getJwtForVehicles = async init => {
	return getJwt(init, 'getJwtForVehicles');
};

export const getJwtForSite = async init => {
	return getJwt(init, 'getJwtForSite');
};
