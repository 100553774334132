/**
 * Only load CoxIntegartions if url param `_mode` is set to `onlyCoxIntegration`
 *
 * Fails if integrationid is missing from the integrationList
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param { string } integrationId 
 * @param { { _mode } } urlParams 
 * @param { string[] } integrationList 
 * @returns {boolean}
 */
export const onlyCoxIntegration = (
    integrationId,
    urlParams,
    integrationList
) => {
    if (urlParams._mode === 'onlyCoxIntegration') {
        if (!integrationList.includes(integrationId)) {
            return false
        }
    }
    return true
}