import { append } from './utils/append';
import { create } from './create';
import { log, traceMode } from './log';
import { subscribe } from './events';
import {
	insertGalleryContent,
	getGalleryContent,
	getAllGalleryContent
} from './media';

import { insertMenuContent } from './menu';

import { getPageLevelModifications } from './content/osirisContentModifications';
import { trackAPIMethods } from './tracking';
import { loadIntegrations } from './utils/loadIntegrations';
import { load, loadJS, loadCSS, invokeAllLoaders, test } from './load';
import {
	insert,
	insertOnce,
	insertCallToAction,
	insertCallToActionOnce,
	update,
	updateOnce,
	updateLink
} from './edit';

import { modifyContent } from './content';

import {
	invokeStandardEventEmitters,
	invokeInventoryItemEventEmitters,
	invokeInventoryListEventEmitters
} from './events/event-emitters';

import { PrivateUtils, Utils } from './utils';

import { isDocumentReady } from './utils/wait-for';

import { validateNonZeroLengthString } from './utils/validator';

import {
	normalizeIntegrationId,
	normalizeIntegrationType
} from './utils/normalize-id';

const APIMethods = {
	append,
	create,
	insert,
	insertOnce,
	insertCallToAction,
	insertCallToActionOnce,
	insertGalleryContent,
	insertMenuContent,
	update,
	updateOnce,
	updateLink,
	modifyContent,
	load,
	loadJS,
	loadCSS,
	log,
	subscribe,
	test
};

const API = class API {
	constructor(init) {
		let integrationId;
		let integrationType;

		const initializer =
			document.currentScript &&
			typeof document.currentScript.getAttribute === 'function' &&
			document.currentScript.getAttribute('data-web-api-id') !== ''
				? document.currentScript
				: init;

		if (initializer && typeof initializer === 'object') {
			if (
				typeof initializer.getAttribute === 'function' &&
				initializer.getAttribute('data-web-api-id') !== ''
			) {
				integrationId = initializer.getAttribute('data-web-api-id');
				integrationType = initializer.getAttribute('data-web-api-type') || '';
			} else {
				({ integrationId, integrationType } = initializer);
			}
		}

		if (!integrationId && init && init !== '') {
			integrationId = init;
			integrationType = init;
		}

		validateNonZeroLengthString(integrationId, 'integrationId');

		this.init = {
			integrationId: normalizeIntegrationId(integrationId),
			integrationType: normalizeIntegrationType(integrationType)
		};

		this.utils = new Utils(this.init);
		this.privateUtils = new PrivateUtils(this.init);
	}
};

Object.entries(APIMethods).forEach(([name, method]) => {
	API.prototype[name] = function APIMethod(...arg) {
		if (traceMode && name !== 'log') {
			log(`${this.init.integrationId} called ${name}:`, ...arg);
		}
		return method.call(null, this.init, ...arg);
	};
});

const { DDC } = window || {};
DDC.API = API;
DDC.PrivateAPI = DDC.PrivateAPI || {};
DDC.PrivateAPI = {
	...DDC.PrivateAPI,
	notifyDynamicContent: () => {
		invokeStandardEventEmitters();
		invokeAllLoaders();
	},
	notifyInventoryContent: vehicleData => {
		if (vehicleData) {
			invokeInventoryItemEventEmitters(vehicleData);
		} else {
			invokeInventoryListEventEmitters();
		}
	},
	timings: {},
	content: {},
	getGalleryContent,
	getAllGalleryContent,
	getPageLevelModifications,
	integrationsLoaded: false,
	track: trackAPIMethods,
	inventoryData: null
};

// TODO: See if we can do this on-demand in unlockPricing rather than always executing this code.
const initMyCarsProfile = async () => {
	await isDocumentReady();

	// Load the MyCars profile in case it's being utilized by API.utils.unlockPricing.
	if (DDC?.userProfileController?.loadProfile) {
		DDC.userProfileController.loadProfile();
	}
};

const initIntegrations = async () => {
	// Dispatch event to inform that the API is ready
	window.dispatchEvent(new Event('WIAPIReady'));

	// Load the integrations returned from the API config endpoint.
	await loadIntegrations();

	// Dispatch event to inform that the integrations have been processed.
	window.DDC.PrivateAPI.integrationsLoaded = true;
	window.dispatchEvent(new Event('WIAPILoaded'));
};

initMyCarsProfile();
initIntegrations();
