import { Location } from '../../location';
import { getPageMetaData } from '../../utils/get-page-meta-data';
import { hasInventoryData } from '../../utils/helpers';

export class BannerLocation extends Location {
	constructor(name) {
		super(name);
		this.name = name;
	}

	isReady() {
		return hasInventoryData();
	}

	getMeta() {
		return getPageMetaData();
	}
}
