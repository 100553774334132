import { EventEmitter } from '../../event-emitter';
import { getPageMetaData } from '../../../utils/get-page-meta-data';
import { getVehicles } from '../../../utils/get-vehicles';
import { handlePaymentVisibility } from '../../../utils/payments';

export class VehicleDataUpdatedEmitter extends EventEmitter {
	constructor() {
		super('vehicle-data-updated-v1');
	}

	async invoke() {
		// Wait for the page data and vehicle data to load
		const pageData = await getPageMetaData();
		const vehicleData = await getVehicles();

		this.fireEvent({
			payload: {
				pageData,
				vehicleData
			}
		});

		handlePaymentVisibility();
	}
}
