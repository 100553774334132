import { append } from './utils/append';
import { createMenuMarkup } from './utils/markup';
import { getPageMetaData } from './utils/get-page-meta-data';
import { insert } from './edit';
import { trackAPIMethods } from './tracking';
import { waitForMenu } from './utils/wait-for';

const DEVICE_TYPES = ['mobile'];
const POSITIONS = ['top', 'bottom'];
const TARGETS = ['primary-menu'];

// validate the position
// - position: enum{POSITIONS}
const validatePosition = position => {
	if (!POSITIONS.includes(position)) {
		throw new Error(
			`Unsupported menu position: '${position}'. Expected one of: ${POSITIONS.join(
				', '
			)}.`
		);
	}
};

// validate the target
// - type: enum{TARGETS}
const validateTarget = target => {
	if (!TARGETS.includes(target)) {
		throw new Error(
			`Unsupported menu target: '${target}'. Expected one of: ${TARGETS.join(
				', '
			)}.`
		);
	}
};

// validate the device type
// - type: enum{DEVICE_TYPES}
const validateDeviceType = device => {
	if (!DEVICE_TYPES.includes(device)) {
		throw new Error(
			`Unsupported device type: '${device}'. Expected one of: ${DEVICE_TYPES.join(
				', '
			)}.`
		);
	}
};

// If the nav type is fragment, remove the insert location from navs of type header
const formatFragmentNav = (isFragment) => {
	if (isFragment) {
		const headersInFragment = document.querySelectorAll('#ddc-mobile-header-nav[data-location="primary-menu primary-menu-top primary-menu-bottom"]');
		headersInFragment.forEach(headerInFragment => {
			headerInFragment.removeAttribute('data-location');
		});
	}
};

export const insertMenuContent = async (init, target, data) => {
	const pageData = await getPageMetaData();

	validateDeviceType(pageData.layoutType);
	
	const isFragmentNavType = !!document.querySelector('a[data-href="/navigation-fragments/search-navigation-and-contact.htm"]');
	formatFragmentNav(isFragmentNavType);

	const dataArray = Array.isArray(data) ? data : [data];
	validateTarget(target);

	await waitForMenu();

	dataArray.forEach(menuData => {
		const { position, callback } = menuData;

		validatePosition(position);

		const location = `${target}-${position}`;

		// Get a new insert location for the menu items
		// or re-use the existing one if already added.
		insert(
			init,
			location,
			(elem, meta) => {
				// Remove any existing menu items added by this integration
				// so that the new ones can be added instead. This allows
				// the integration to update the state of its menu elements.
				while (elem.hasChildNodes()) {
					elem.removeChild(elem.firstChild);
				}

				// Get the new markup and append it to the location.
				const markup = createMenuMarkup({...menuData, isFragmentNavType});

				if (!markup) {
					return;
				}

				const appendedElem = append(init, elem, markup);

				if (callback) {
					callback(appendedElem, meta, menuData);
				}
			},
			true
		);

		trackAPIMethods(init, {
			methodType: 'insertMenuContent',
			location
		});
	});

	return true;
};
