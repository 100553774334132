import { log } from '../../../log'
import * as T_LOG from '../globals'

// Handlers
const handleCoxOnly = (payload) => {
	const { integrationId, integrationUrl } = payload
	log(
		integrationId,
		'External integrations are disabled due to onlyCoxIntegration mode.',
		`Skipping JS: ${integrationUrl}`
	)
}

const handleDebugThirdParty = (payload) => {
	const { integrationId, integrationUrl } = payload
	log(
		integrationId,
		'This integration is disabled because Third Party Debug is enabled.',
		`Skipping JS: ${integrationUrl}`
	)
}

const handleDeviceEnablement = (payload) => {
	const { integrationId } = payload
	log(integrationId, `Config for ${integrationId} found but not enabled for the current device type.`)
}

const handleDisableThirdParty = (payload) => {
	const { integrationId } = payload
	log(`Skipping load of ${integrationId} due to _onlyDisableThirdParty URL parameter.`)
}

const handleEnableThirdParty = (payload) => {
	const { integrationId } = payload
	log(`Skipping load of ${integrationId} due to _onlyEnableThirdParty URL parameter.`)
}

const handleInvalidProtocol = (payload) => {
	const { integrationId } = payload
	log(
		`ERROR with '${integrationId}' integration setup:`,
		'URL defined does not start with https:// or / and HTTPS is required.'
	)
}

const handleResetCustom = (payload) => {
	const { integrationId, integrationUrl } = payload
	log(
		integrationId,
		`Integrations are disabled. Skipping JS: ${integrationUrl}`
	)
}

const handleLoadingIntegrationJs = (payload) => {
	const { integrationId, integrationUrl } = payload
	log(
		integrationId,
		`Loading Integration JS: ${integrationUrl}`
	);
}

const handleDefault = () => {
	return undefined
}

// Builder Reducer
/**
 * @param { string } type 
 * @param { { integrationId: string, integrationUrl?: string } } payload 
 * @returns { void }
 */
export const logBuilder = (
	type,
	payload
) => {
	switch (type) {
		case T_LOG.COX_ONLY:				return handleCoxOnly(payload)
		case T_LOG.DEBUG_THIRD_PARTY:		return handleDebugThirdParty(payload)
		case T_LOG.DEVICE_ENABLEMENT:		return handleDeviceEnablement(payload)
		case T_LOG.DISABLE_THIRD_PARTY:		return handleDisableThirdParty(payload)
		case T_LOG.ENABLE_THIRD_PARTY:		return handleEnableThirdParty(payload)
		case T_LOG.INVALID_PROTOCOL:		return handleInvalidProtocol(payload)
		case T_LOG.RESET_CUSTOM:			return handleResetCustom(payload)
		case T_LOG.LOADING_INTEGRATION_JS:	return handleLoadingIntegrationJs(payload)
		default: return handleDefault()
	}
}