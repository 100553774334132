import { Editor } from './editor';
import { trackAPIMethods } from '../tracking';
import {
	INSERT,
	MODIFY_CTA,
	RESTRICTIVELY_MODIFY_CTA,
	MODIFY_LINKS,
	UPDATE
} from './constants';
import { allowSelectedUpdatesToButtonIntents } from './locations';
import { calculateTargetListingLocation } from './listingLocationInsertion';
import { getConfig } from '../utils/load-configs';
import { log } from '../log';
import '../../types/edit.d'

const standardModifiers = new Set();
const vehicleModifiers = new Set();
export const transientVehicleModifiers = new Set();

/**
 * For each stored Editor with the StandardModifier label,
 * Check if the Editor should be invoked with `Editor.apply()`.
 * 
 * @param { InvokeStandardInsertsParams? } settings 
 */
export const invokeStandardInserts = (settings = {}) => {
	standardModifiers.forEach(insert => {
		const { exclude, include } = settings;

		if (!insert.targetLocation) {
			log('No target location was set for insert:', insert);
			return;
		}

		// Skip invokation if the targetLocation is excluded
		if (exclude && insert.targetLocation.startsWith(exclude)) {
			return;
		}

		// Skip invokation if the targetLocation is not included
		if (include && !insert.targetLocation.startsWith(include)) {
			return;
		}

		insert.apply();
	});
};

/**
 * For each stored Editor with the StandardModifier label,
 * invoke with `Editor.apply()`.
 */
export const invokeStandardUpdates = () => {
	standardModifiers.forEach(update => {
		update.apply();
	});
};

/**
 * For each stored Editor with the transientVehicleModifiers or vehicleModifiers label,
 * invoke with `Editor.apply()` with the `vehicleData` and a `true` `isVehicleEvent`
 * 
 * @param { InvokeVehicleInsertsAndUpdatesParams } vehicleData 
 */
export const invokeVehicleInsertsAndUpdates = vehicleData => {
	transientVehicleModifiers.forEach(insert => {
		insert.apply(vehicleData, true);
	});
	vehicleModifiers.forEach(insert => {
		insert.apply(vehicleData, true);
	});
};

const edit = (
	methodName,
	methodType,
	init,
	location,
	type,
	intent,
	callback,
	subscribe = true
) => {
	const editor = new Editor(
		init,
		methodName,
		methodType,
		location,
		type,
		intent,
		callback
	);

	const isNewSrpVehicleLocation =
		window.DDC.InvData && location !== null && location.startsWith('vehicle');

	if (subscribe) {
		if (
			methodType === INSERT ||
			[UPDATE, MODIFY_CTA, RESTRICTIVELY_MODIFY_CTA].includes(methodType)
		) {
			if (location.startsWith('vehicle')) {
				vehicleModifiers.add(editor);
			} else {
				standardModifiers.add(editor);
			}
		}
	} else if (!subscribe && isNewSrpVehicleLocation) {
		transientVehicleModifiers.add(editor);
	}

	editor.apply();

	const locationName = intent || location;
	trackAPIMethods(init, { methodType, locationName });

	return editor;
};

export const insert = async (init, location, callback, subscribe = true) => {
	if (typeof init !== 'object' || init === null) {
		throw new Error(`init must be an object. Got: ${init}.`);
	}

	const targetLocation = await calculateTargetListingLocation(init, location);

	return edit(
		'insert',
		INSERT,
		init,
		targetLocation,
		null,
		null,
		callback,
		subscribe
	);
};

export const insertOnce = async (init, location, callback) => {
	return insert(init, location, callback, false);
};

export const insertCallToAction = async (
	init,
	type,
	intent,
	callback,
	subscribe = true
) => {
	let location = null;
	let ctaIntent;

	const config = await getConfig(init);

	if (type === 'button') {
		location = config?.useCustomInsertLocations
			? 'vehicle-ctas-custom'
			: 'vehicle-ctas';
		ctaIntent = `vehicle-${intent}-button`;
	}

	const methodType = allowSelectedUpdatesToButtonIntents.includes(intent)
		? RESTRICTIVELY_MODIFY_CTA
		: MODIFY_CTA;

	return edit(
		'insertCallToAction',
		methodType,
		init,
		location,
		type,
		ctaIntent,
		callback,
		subscribe
	);
};

export const insertCallToActionOnce = async (init, type, intent, callback) => {
	return insertCallToAction(init, type, intent, callback, false);
};

export const update = async (init, location, callback, subscribe = true) => {
	return edit(
		'update',
		UPDATE,
		init,
		location,
		null,
		null,
		callback,
		subscribe
	);
};

export const updateOnce = async (init, location, callback) => {
	return update(init, location, callback, false);
};

export const updateLink = (init, intent, callback, subscribe = false) => {
	return edit(
		'updateLink',
		MODIFY_LINKS,
		init,
		null,
		'links',
		intent,
		callback,
		subscribe
	);
};
