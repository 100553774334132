import { PRIMARY_BANNER } from './constants';
import { enableInlineBanner } from '../utils/helpers';
import { PRIMARY_PLACEHOLDER_INTEGRATIONS } from './constants';

const insertions = new Set();
const listingsPlaceholderPrefix = 'listings-placeholder';
let allListingElems = null;
let observerPromise = null;

// 1. Get the listing locations
const checkAndResolve = (listingElems, observer, resolve) => {
	if (listingElems.size >= 5) {
		if (observer) {
			observer.disconnect();
		}
		allListingElems = listingElems;
		resolve(listingElems);
	}
};

const getAllListingLocationElems = () => {
	if (observerPromise) {
		return observerPromise;
	}
	const selector = `[data-location*="${listingsPlaceholderPrefix}"]`;
	const elems = document.querySelectorAll(selector);
	const listingElems = new Map();
	elems.forEach(newElem => {
		const location = newElem.getAttribute('data-location');
		const existListingElems = listingElems.get(location) || [];
		listingElems.set(location, [...existListingElems, newElem]);
	});

	observerPromise = new Promise(resolve => {
		// Check if we already have all 5 locations
		checkAndResolve(listingElems, null, resolve);
		const observer = new MutationObserver(mutationsList => {
			mutationsList.forEach(mutation => {
				if (mutation.type === 'childList') {
					const newElems = mutation.target.querySelectorAll(selector);
					newElems.forEach(newElem => {
						const location = newElem.getAttribute('data-location');
						const existListingElems = listingElems.get(location) || [];
						listingElems.set(location, [...existListingElems, newElem]);
					});
					checkAndResolve(listingElems, observer, resolve);
				}
			});
		});
		const container = document.querySelector('.srp-wrapper-listing');
		if (container) {
			observer.observe(container, { childList: true, subtree: true });
		}
	});
	return observerPromise;
};

// 2. Check if a listings location or other type of location
const isListingLocation = location => {
	return !!location?.includes(listingsPlaceholderPrefix);
};

// 3.) Check if location is already used by an integration
export const isInsertedListingLocation = location => {
	return insertions.has(location);
};

// 4.) Set location after use so the next integration does not use it.
export const setInsertedListingLocation = location => {
	if (!isListingLocation(location)) {
		return;
	}
	insertions.add(location);
};

// 5.) Only allow specific integrations to insert into the primary placeholder
export const isPrimaryPlaceholderIntegration = integrationId => {
	return PRIMARY_PLACEHOLDER_INTEGRATIONS.includes(integrationId);
};

// 6.) Check if the target location can be inserted into.
const isInsertableToPlaceholder = async (init, location) => {
	// Placeholder 5 is always insertable
	if (location === `${listingsPlaceholderPrefix}-5`) {
		return true;
	}

	if (location === `${listingsPlaceholderPrefix}-1`) {
		// Always allow integrations listed in the PRIMARY_PLACEHOLDER_INTEGRATIONS array to insert into the primary location.
		if (isPrimaryPlaceholderIntegration(init.integrationId)) {
			return true;
		}

		if (!(window.DDC.PrivateAPI.hasPrimaryPlaceholderIntegration || allListingElems)) {
			allListingElems = await getAllListingLocationElems();
		}

		// Only insertable if not a PRIMARY_PLACEHOLDER_INTEGRATIONS integration and not already occupied by another integration.
		return !(
			window.DDC.PrivateAPI.hasPrimaryPlaceholderIntegration ||
			isInsertedListingLocation(location)
		);
	}

	if (!allListingElems) {
		allListingElems = await getAllListingLocationElems();
	}

	const elems = allListingElems?.get(location) || [];

	// Can not insert if not found location or location was occupied by another WIAPI
	if (elems.length === 0 || isInsertedListingLocation(location)) {
		return false;
	}

	return true;
};

// 7.) Get the real target location to insert into, which may be the current location or the next placeholder location.
const getFirstAvailableListingLocation = async (init, currentLocation) => {
	const isInsertable = await isInsertableToPlaceholder(init, currentLocation);

	// Return current location if it insertable
	if (isInsertable) {
		return currentLocation;
	}

	if (!allListingElems) {
		allListingElems = await getAllListingLocationElems();
	}

	const allAvailableListingLocations = Array.from(allListingElems.keys());

	// Sort available locations from listing-placeholder one to five
	allAvailableListingLocations.sort();

	// Find the first available location that insertable and not a current location
	// because already check that current location is not insertable and no need to check again
	const nextLocation = allAvailableListingLocations.find(
		availableLocation =>
			!isInsertedListingLocation(availableLocation) &&
			availableLocation !== currentLocation &&
			isInsertableToPlaceholder(init, availableLocation)
	);

	if (!nextLocation) {
		return `${listingsPlaceholderPrefix}-5`;
	}

	return nextLocation;
};

export const calculateTargetListingLocation = async (init, location) => {
	let targetLocation = location;

	// Default to listings-placeholder-1 for primary banner before calculating available locations
	if (targetLocation === PRIMARY_BANNER && enableInlineBanner()) {
		targetLocation = `${listingsPlaceholderPrefix}-1`;
	}

	if (!isListingLocation(targetLocation)) {
		return targetLocation;
	}

	// Calculate available location 
	const availableLocation = await getFirstAvailableListingLocation(
		init,
		targetLocation
	);

	setInsertedListingLocation(availableLocation);

	return availableLocation;
};
