import { validateInitIsAnObject } from '../utils/validator';

export class Subscription {
	constructor(init, callback) {
		validateInitIsAnObject(init, 'init');

		this.init = init;
		this.callback = callback;
	}
}
