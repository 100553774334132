/**
 * Disable Integrations if one of the following URL params are set:
 * 
 * 1. _mode=resetCustom
 * 2. _disableTPS=true&_enableWIAPI=false
 * 3. _disableWIAPI=true
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param {{ _mode?: string, _disableTPS?: string, _enableWIAPI?: string, _disableWIAPI?: string}} urlParams 
 * @returns 
 */
export const resetCustom = (urlParams) => {
    if (
        urlParams._mode === 'resetCustom' ||
        (
            urlParams._disableTPS === 'true' &&
            urlParams._enableWIAPI !== 'true'
        ) ||
        urlParams._disableWIAPI === 'true'
    ) {
        return false
    }
    return true
}