const fetchPriorityFlagJS = window?.DDC?.Flags['wiapi-fetch-priority-js'];
const fetchPriorityFlagCSS = window?.DDC?.Flags['wiapi-fetch-priority-css'];
const inlinePrimaryBannerFlag =
	window?.DDC?.Flags['wiapi-inline-primary-banner'];
const fetchPriorityJS =
	fetchPriorityFlagJS && fetchPriorityFlagJS !== 'false'
		? fetchPriorityFlagJS
		: false;
const fetchPriorityCSS =
	fetchPriorityFlagCSS && fetchPriorityFlagCSS !== 'false'
		? fetchPriorityFlagCSS
		: false;

const inlinePrimaryBanner = inlinePrimaryBannerFlag || false;
const onlyCoxIntegrations =
	window?.DDC?.Flags['enabled-wise-tps-integration-list'] || '';
export const featureFlags = {
	fetchPriorityJS,
	fetchPriorityCSS,
	inlinePrimaryBanner,
	onlyCoxIntegrations
};
