export const trackTiming = (category, timingKey, timing) => {
	if (window.DDC.PrivateAPI === undefined) {
		window.DDC.PrivateAPI = {};
	}

	if (window.DDC.PrivateAPI.timings === undefined) {
		window.DDC.PrivateAPI.timings = {};
	}

	if (window.DDC.PrivateAPI.timings[category] === undefined) {
		window.DDC.PrivateAPI.timings[category] = {};
	}

	if (window.DDC.PrivateAPI.timings[category][timingKey] === undefined) {
		window.DDC.PrivateAPI.timings[category][timingKey] = [];
	}

	window.DDC.PrivateAPI.timings[category][timingKey].push(timing);
};
