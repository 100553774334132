import { trackAPIMethods } from '../tracking';

export const getDealershipData = () => {
	const { dealership = {}, site = {} } = window.DDC.dataLayer || {};

	// Merge the array of dealerCode objects to a single object
	// to match the format of the Vehicle Events.
	const dealerCodes = {};

	dealership.dealerCode.forEach(entry => {
		Object.assign(dealerCodes, entry);
	});

	return {
		dealershipAddress1: dealership.address1 || '',
		dealershipAddress2: dealership.address2 || '',
		dealershipCity: dealership.city || '',
		dealershipCodes: dealerCodes || [],
		dealershipCountry: dealership.country || '',
		dealershipFranchises: site.franchises || [],
		dealershipName: dealership.dealershipName || '',
		dealershipPostalCode: dealership.postalCode || '',
		dealershipStateProvince: dealership.stateProvince || ''
	};
};

export const getDealerData = init => {
	return new Promise((resolve, reject) => {
		const { dealership = {} } = window.DDC.dataLayer || {};
		if (Object.keys(dealership)) {
			trackAPIMethods(init, {
				methodType: 'getDealerData',
				status: 'Success'
			});
			resolve(getDealershipData());
			return;
		}

		trackAPIMethods(init, {
			methodType: 'getDealerData',
			status: 'Failed'
		});
		reject(new Error('Unable to retrieve dealership data.'));
	});
};
