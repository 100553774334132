import {
	validateNonZeroLengthString,
	validateTypeMap
} from '../utils/validator';
import { trackAPIMethods } from '../tracking';
import { featureFlags } from '../utils/feature-flags';
import {
	normalizeIntegrationId,
	normalizeIntegrationType
} from '../utils/normalize-id';
import { log } from '../log';
import '../../types/init.d';

export class Loader {

	/**
	 * @param { string | Init } init 
	 * @param { string } src URL point to the asset to load.
	 * @param { 'css'|'js' } type The type of file loaded from `src` either javascript or css.
	 * @param { Map<string, string>} attr Map of HTML attributes.
	 * @param { boolean } isIntegrationLoad 
	 */
	constructor(init, src, type, attr, isIntegrationLoad) {
		// Type validation
		const integrationId = typeof init === 'string' ? init : init.integrationId;
		const integrationType =
			typeof init === 'string' ? init : init.integrationType;
		validateNonZeroLengthString(integrationId, 'integrationId');
		validateNonZeroLengthString(src, 'src');
		validateNonZeroLengthString(type, 'type');
		validateTypeMap(attr, 'attributes');

		// instanced values
		/** @type { Init } */
		this.init = {
			integrationId: normalizeIntegrationId(integrationId),
			integrationType: normalizeIntegrationType(integrationType)
		};
		this.src = src;
		this.fileType = type;
		this.attr = attr;
		this.immutableAttr = [
			'data-web-api-id',
			'data-web-api-type',
			'async',
			'type',
			'src'
		];
		this.methodType = this.fileType
			? `load${this.fileType.toUpperCase()}`
			: 'Load';
		this.isIntegrationLoad = isIntegrationLoad;
		this.load = this.load.bind(this);
		this.invoked = false;
	}

	/**
	 * Load an asset onto the page.
	 * 
	 * @param { 'all' | 'js' | 'css' } scope Scope of files to load.
	 */
	load(scope) {
		if (this.invoked) {
			return new Promise(resolve => {
				resolve(`Resource ${this.src} already loaded.`);
			});
		}

		/**
		 * @param { 'Success' | 'Failed' } result 
		 */
		const trackLoaderResult = result => {
			const methodType = this.isIntegrationLoad
				? 'Integration Loaded'
				: this.methodType;
			trackAPIMethods(this.init, {
				methodType,
				load: this.src,
				status: result
			});
		};

		const insertTag = () => {
			this.invoked = true;
			return new Promise((resolve, reject) => {
				const tag = this.fileType === 'js' ? 'script' : 'link';
				const tagElement = document.createElement(tag);
				tagElement.setAttribute('data-web-api-id', this.init.integrationId);
				tagElement.setAttribute('data-web-api-type', this.init.integrationType);

				switch (tag) {
					case 'script':
						tagElement.type = 'text/javascript';
						tagElement.src = this.src;
						tagElement.async = true;
						if (featureFlags.fetchPriorityJS) {
							tagElement.setAttribute(
								'fetchpriority',
								featureFlags.fetchPriorityJS
							);
						}
						this.attr.forEach((attrValue, attrKey) => {
							switch (attrKey) {
								case 'priority': {
									// Skip this attribute
									// `priority` is used to set location of the script
									// [head,body,standard]
									break;
								}
								case 'jstype':
								case 'type': {
									// Don't allow the 'type' attribute to be overridden, unless it's a type of module.
									if(attrValue === 'module') {
										tagElement.setAttribute('type', 'module')
									}
									break;
								}
								case 'synchronicity': {
									if(attrValue === 'async') {
										tagElement.setAttribute('async', 'async')
									}
									break;
								}
								default: {
									if (!this.immutableAttr.includes(attrKey)) {
										tagElement.setAttribute(attrKey, attrValue);
									}
									break;
								}
							}
						});
						/**
						 * script assets are only loaded onto the body if either:
						 * - they are priority of `standard`
						 * - they are a third party integration
						 * 
						 * filter out scripts with a priority of `body` or `head`
						 * these are handled in cms-web
						*/
						if (['body', 'head'].includes(this.attr.get('priority'))) {
							return
						}
						document.body.appendChild(tagElement);
						break;

					case 'link':
						tagElement.type = 'text/css';
						tagElement.rel = 'stylesheet';
						tagElement.media = 'all';
						tagElement.href = this.src;
						if (featureFlags.fetchPriorityCSS) {
							tagElement.setAttribute(
								'fetchpriority',
								featureFlags.fetchPriorityCSS
							);
						}
						document.head.appendChild(tagElement);
						break;

					default:
				}

				tagElement.onload = () => {
					this.invoked = true;
					trackLoaderResult('Success');
					resolve(this.src);
				};

				tagElement.onerror = () => {
					trackLoaderResult('Failed');
					reject(this.src);
				};
			}).catch(error => {
				log(
					this.init.integrationId,
					`ERROR: ${error.toString()} failed to load!`
				);
			});
		};

		if (['all', 'css'].includes(scope) && this.fileType === 'css') {
			return insertTag();
		}

		if (['all', 'js'].includes(scope) && this.fileType === 'js') {
			return insertTag();
		}

		if (this.fileType === 'unknown') {
			return new Promise((_resolve, reject) => {
				reject(
					new Error(`Failed to load unknown resource type of file ${this.src}`)
				);
			});
		}
		return false;
	}
}
