/**
 * Given a breadcumb list of strings, retrieve a nested value out of an object. Returns `null` if the value does not exist.
 * 
 * example:
 * ```javascript
 * 	const keynameBreadcrumbList = ['foo', 'bar'];
 * 	const obj = { foo: { bar: 'nestedValue' } };
 * 	const result = 'nestedValue'
 * ```
 * 
 * @param { string[] } keynameBreadcrumbList a breadcrumb list of deeply nested keynames to traverse down
 * @param { * } obj Object to check for nested values
 * @returns { any | null }
 */
export const getProp = (keynameBreadcrumbList, obj) => {
    const property = keynameBreadcrumbList.reduce((propObj,keyname) => {
            // Check if next level of nesting exists
            if(propObj && propObj[keyname]) {
                // Progress getProp reduce to next level of nesting
                return propObj[keyname]
            }
            return null
        },
        obj /** initial value of the reduce */
    );
    return property;
}