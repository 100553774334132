export const convertStringToObject = obj => {
	const output = obj
		.replace('{', '')
		.replace('}', '')
		.split(',')
		.map(keyVal => {
			return keyVal.split('=').map(_ => _.trim());
		})
		.reduce((acc, [key, val]) => {
			acc[key] = val;
			return acc;
		}, {});
	return output;
};

export const convertStringToArray = str => {
	const re = /[[\]\s]/gi;
	return str.replace(re, '').split(',');
};

export const convertFormattedPriceToInt = value => {
	if (!value || value === '0' || value === 'PLEASE_CALL') {
		return 0;
	}
	return (
		parseInt(
			value
				.replace(' ', '')
				.replace(' ', '')
				.replace('$', '')
				.replace(',', '')
				.trim(),
			10
		) || 0
	);
};

export const titleCase = (inputStr, delimiter = ' ') => {
	let str = inputStr.toLowerCase();
	str = str.split(delimiter);
	for (let i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
};
