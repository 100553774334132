import { listOfAllowedModificationLocation } from '../content/locations';

export const validateNonZeroLengthString = (str, name) => {
	if (typeof str !== 'string' || str.length === 0) {
		throw new Error(`${name} must be a non-zero length string. Got: ${str}.`);
	}
};

export const validateIsAnObject = (testItem, name) => {
	if (!testItem || typeof testItem !== 'object') {
		throw new Error(`${name} must be an object. Got: ${testItem}.`);
	}
};

const validateObjectHasAllowedProprties = (
	testObject,
	allowedProperties = [],
	objDesc
) => {
	const propertiesInTestObject = Object.keys(testObject);
	const invalidProps = [];
	const arePropertiesInTestObjectValid = propertiesInTestObject.every(prop => {
		const isAllowedProp = allowedProperties.indexOf(prop) >= 0;
		if (!isAllowedProp) invalidProps.push(prop);
		return isAllowedProp;
	});

	if (!arePropertiesInTestObjectValid) {
		throw new Error(
			`Found the following invalid props '${invalidProps.join(
				', '
			)}'. Only the following props are allowed '${allowedProperties.join(
				', '
			)}', in ${objDesc}.`
		);
	}
};

export const validateInitIsAnObject = (init, name) => {
	validateIsAnObject(init, name);

	validateNonZeroLengthString(init.integrationId, 'integrationId');
	validateNonZeroLengthString(init.integrationType, 'integrationType');
};

export const validateTypeMap = (property, name) => {
	if (!(property instanceof Map)) {
		throw new Error(`${name} must be a Map. Got: ${typeof property}.`);
	}
};

export const validateIfLocationIsModifiable = locationName => {
	validateNonZeroLengthString(locationName, 'locationName');

	if (!listOfAllowedModificationLocation.includes(locationName)) {
		throw new Error(
			`Invalid location: ${locationName}. Valid options ` +
				`are: ${listOfAllowedModificationLocation.join(', ')}.`
		);
	}
};

export const validateTheModifierObjectForLocation = (
	locationName,
	modifierObject
) => {
	validateIsAnObject(modifierObject, 'Modifier');

	const { schema } = modifierObject;

	switch (locationName) {
		case 'schedule-service': {
			validateIsAnObject(schema, 'Schema of the modifier object');
			validateObjectHasAllowedProprties(
				modifierObject,
				['schema'],
				`Modifier object of ${locationName}.`
			);
			validateObjectHasAllowedProprties(
				schema,
				['href', 'target', 'onclick', 'popover', 'attributes'],
				`the schema of the modifier object  of ${locationName}.`
			);
			break;
		}

		default:
			break;
	}
};
