import { VehicleLocation } from './vehicle-location';

export class MediaLocation extends VehicleLocation {
	async getConfig() {
		return {
			classes: 'badge m-0 mt-3',
			elementType: 'li',
			gridSrpClasses: 'badge m-0',
			placement: 'first',
			responsiveSrpClasses: 'm-0 mb-3 px-3',
			vdpClasses: 'm-0'
		};
	}
}
