import { hasPlaceHolders, loadConfigs, replacePlaceHolders } from '../load-configs'
import { getPageMetaData } from '../get-page-meta-data';
import { getUrlParams } from '../helpers';
import { isPrimaryPlaceholderIntegration } from '../../edit/listingLocationInsertion'
import { updateSettingsFromConfig } from '../settings';
import { log } from '../../log';
import { loadJS } from '../../load';
import { Validator } from './validator';
import { featureFlags } from '../feature-flags';
import { loadAttrs } from '../loadAttrs/loadAttrs';

import {
	ALLOWED_SALES_INTEGRATIONS
} from '../../edit/constants.js';


const integrationList = featureFlags.onlyCoxIntegrations
	.split(',')
	.map(id => id.trim());

export const loadIntegrations = async () => {
	const configs = await loadConfigs();
	const pageData = await getPageMetaData();
	const urlParams = getUrlParams();

	const loadPromises = Object.keys(configs).reduce((promiseList, integrationId) => {
		const config = configs[integrationId]

		if(
			config &&
			config.fields &&
			config.fields.integrationUrl || config.urls
		) {
			const {
				debugMode,
				fields,
				wiseIntegration,
			} = config
			const {
				enabledDesktop,
				enabledSeamless,
				excludedPages
			} = fields;

			const { pageName, layoutType } = pageData;
			
			if (wiseIntegration) {
				const { urls } = config
				/**
				 * WISE Integrations use a urls key with multiple integrationURLs
				 * Each url needs to be individually loaded
				 * 
				 * @type { Promise<boolean> }
				 */
				const urlsPromises = urls.map((urlItem) => {
					return new Promise((resolve) => {

						// Vars unique to a WISE Integration
						let {
							url: integrationUrl
						} = urlItem
						const {
							types: urlAttributes
						} = urlItem
						const integrationType = 'wise'
						
						// Validate the integration Configuration
						const validator = new Validator(
							debugMode,
							enabledDesktop,
							enabledSeamless,
							integrationId,
							integrationUrl,
							integrationList,
							layoutType,
							urlParams,
							wiseIntegration
						)
						validator.all()

						let loadIntegration = false;
						let excludedFromPage = false;
						let hasAddToPagesSettings = false;

						if (excludedPages) {
							excludedPages.split(',').forEach(targetPage => {
								if (pageName.match(targetPage)) {
									excludedFromPage = true;
								}
							});
						}

						if (!excludedFromPage) {
							Object.keys(fields).forEach(field => {
								if (field.startsWith('addTo') || field === 'includedPages') {
									hasAddToPagesSettings = true;
									fields[field].split(',').forEach(targetPage => {
										if (targetPage === 'ALL' || pageName.match(targetPage)) {
											loadIntegration = true;
										}
									});
								}
							});

							// In the case where addToAllPages is not specified but implied, load the integration anyway.
							// We will want to deprecate this functionality as we move to WISE to make this decision explicit.
							if (!loadIntegration && !hasAddToPagesSettings) {
								loadIntegration = true;
							}
							//Override for Sales domain to only load integrations that are allowed
							if((pageData.pageUrl.includes('cms.sales.apps.dealer.com') || pageData.pageUrl.includes('cms.sales.apps-np.dealer.com') )&& !ALLOWED_SALES_INTEGRATIONS.includes(integrationId)){
								loadIntegration = false;
							}
							
						}

						if (loadIntegration) {
							if (isPrimaryPlaceholderIntegration(integrationId)) {
								window.DDC.PrivateAPI.hasPrimaryPlaceholderIntegration = true;
							}

							updateSettingsFromConfig(fields);

							if (
								integrationUrl.startsWith('/') &&
								!integrationUrl.startsWith('//') &&
								!integrationUrl.startsWith('/static/')
							) {
								integrationUrl = `/static${integrationUrl}`;
							}

							const url = hasPlaceHolders(integrationUrl)
								? replacePlaceHolders(
									integrationUrl,
									configs[integrationId].fields
								)
								: integrationUrl;

							log(integrationId, `Loading Integration JS: ${url}`);
							loadJS(
								{
									integrationId,
									integrationType
								},
								url,
								loadAttrs(urlAttributes),
								true /* Inform new relic that we are calling loadJS instead of an already-loaded integration calling it */
							);
						} else {
							log(
								integrationId,
								`Config for ${integrationId} found but excluded for the current page: ${pageName}.`
							);
						}

						// Integration Loaded!
						resolve(true)
					})
				})
				return [...promiseList, ...urlsPromises]
			} else {
				/**
				 * non-WISE Integrations load a single URL
				 * 
				 * @type { Promise<boolean> }
				 */
				const fieldPromise = new Promise((resolve) => {

					// Vars unique to a non-WISE Integration
					let { integrationUrl } = fields
					const integrationType = 'api';

					// Validate the integration Configuration
					const validator = new Validator(
						debugMode,
						enabledDesktop,
						enabledSeamless,
						integrationId,
						integrationUrl,
						integrationList,
						layoutType,
						urlParams,
						wiseIntegration
					)
					validator.all()

					let loadIntegration = false;
					let excludedFromPage = false;
					let hasAddToPagesSettings = false;

					if (excludedPages) {
						excludedPages.split(',').forEach(targetPage => {
							if (pageName.match(targetPage)) {
								excludedFromPage = true;
							}
						});
					}

					if (!excludedFromPage) {
						Object.keys(fields).forEach(field => {
							if (field.startsWith('addTo') || field === 'includedPages') {
								hasAddToPagesSettings = true;
								fields[field].split(',').forEach(targetPage => {
									if (targetPage === 'ALL' || pageName.match(targetPage)) {
										loadIntegration = true;
									}
								});
							}
						});

						// In the case where addToAllPages is not specified but implied, load the integration anyway.
						// We will want to deprecate this functionality as we move to WISE to make this decision explicit.
						if (!loadIntegration && !hasAddToPagesSettings) {
							loadIntegration = true;
						}
					}

					if (loadIntegration) {
						if (isPrimaryPlaceholderIntegration(integrationId)) {
							window.DDC.PrivateAPI.hasPrimaryPlaceholderIntegration = true;
						}

						updateSettingsFromConfig(fields);

						if (
							integrationUrl.startsWith('/') &&
							!integrationUrl.startsWith('//') &&
							!integrationUrl.startsWith('/static/')
						) {
							integrationUrl = `/static${integrationUrl}`;
						}

						const url = hasPlaceHolders(integrationUrl)
							? replacePlaceHolders(
								integrationUrl,
								configs[integrationId].fields
							)
							: integrationUrl;

						log(integrationId, `Loading Integration JS: ${url}`);
						loadJS(
							{
								integrationId,
								integrationType
							},
							url,
							new Map(),
							true /* Inform new relic that we are calling loadJS instead of an already-loaded integration calling it */
						);
					} else {
						log(
							integrationId,
							`Config for ${integrationId} found but excluded for the current page: ${pageName}.`
						);
					}

					// Integration Loaded!
					resolve(true)
				})
				return [...promiseList, fieldPromise]
			}
		} else {
			return promiseList
		}
	}, [])

	const res = await Promise.allSettled(loadPromises)
	return res
};
