import {
	invokeStandardEventEmitters,
	invokeInventoryItemEventEmitters,
	invokeInventoryListEventEmitters
} from './event-emitters';

import {
	transientVehicleModifiers,
	invokeStandardInserts,
	invokeVehicleInsertsAndUpdates
} from '../edit';

import { getVehicles } from '../utils/get-vehicles';

import { getPageMetaData } from '../utils/get-page-meta-data';

import {
	hasInventoryData,
	hasDataBusInventory,
	clearObservers,
	query
} from '../utils/helpers';

import { isDocumentReady } from '../utils/wait-for';

let eventsInitialized = false;

// Clean up data from the previous SRP view.
export const refreshData = async () => {
	clearObservers();
	transientVehicleModifiers.clear();
	window.DDC.PrivateAPI.inventoryData = {};
	await getVehicles();
	return true;
};

export const fireVehicleInsertsAndUpdates = uuid => {
	if (!uuid || uuid.length < 32) {
		return true;
	}

	// Fire the item event emitters (vehicle-shown-v1)
	invokeInventoryItemEventEmitters({ uuid });

	// Insert the content from subscribers into the vehicle card.
	invokeVehicleInsertsAndUpdates({ uuid });

	return true;
};

export const activatePlaceholder = location => {
	invokeStandardInserts({ include: location });
};

export const activateVehicleCard = data => {
	const { id, isLocation } = data.detail;

	// Placeholder Cards
	if (isLocation) {
		activatePlaceholder(id);
	} else {
		fireVehicleInsertsAndUpdates(id);
	}
};

export const activateItemListeners = async () => {
	// Vehicle Cards

	// Replay events that were fired before the API loaded.
	if (window?.DDC?.APIEvents?.get) {
		window.DDC.APIEvents.get().forEach(data => {
			activateVehicleCard(data);
		});
		window.DDC.APIEvents.received = [];
	}

	window.addEventListener('wsInvListingShown', data => {
		activateVehicleCard(data);
	});

	// Placeholder Locations added after initial page rendering
	window.addEventListener('wiapiPlaceholderAvailable', data => {
		activatePlaceholder(data.detail.location);
	});

	return activatePlaceholder('listings-');
};

export const setupItemEvents = async () => {
	window.DDC.PrivateAPI = window.DDC.PrivateAPI || {};
	window.DDC.PrivateAPI.inventoryData = {};
	await refreshData();
	await invokeInventoryListEventEmitters();
	await activateItemListeners();
	return true;
};

export const reprocessDisplayedItems = () => {
	document.querySelectorAll('[data-uuid]').forEach(card => {
		const uuid = card.getAttribute('data-uuid');
		activateVehicleCard({
			detail: {
				id: uuid,
				isLocation: false
			}
		});
	});
};

export const initializeEvents = async () => {
	await invokeStandardEventEmitters();

	const pageMetaData = await getPageMetaData();
	if (!(pageMetaData?.searchPage || pageMetaData?.detailPage)) {
		return;
	}

	await hasInventoryData();

	if (await hasDataBusInventory()) {
		const initializeSRPEvents = async () => {
			if (eventsInitialized) {
				return true;
			}
			eventsInitialized = true;

			let debouncing = null;
			let previousViewportWidth = window.innerWidth;
			let currentViewportWidth = 0;

			const updateLayout = breakpoint => {
				clearTimeout(debouncing);

				currentViewportWidth = window.innerWidth;

				debouncing = setTimeout(async () => {
					// Only replay the events when the user's browser size passes the breakpoint to avoid sending events unnecessarily.
					if (
						(currentViewportWidth <= breakpoint &&
							previousViewportWidth >= breakpoint) ||
						(currentViewportWidth >= breakpoint &&
							previousViewportWidth <= breakpoint)
					) {
						previousViewportWidth = window.innerWidth;

						setupItemEvents();
					}
				}, 500);
			};

			const updateLayoutListeners = () => {
				const target = query(
					'#card-layout-toggle-portal button.active .ddc-icon'
				);
				if (!target) {
					return;
				}
				const isGridView = target.classList.contains('ddc-icon-layout-grid');
				const breakpoint = isGridView ? 1440 : 768;
				updateLayout(breakpoint);
			};

			// Replay events when the browser is resized or orientation is changed.
			window.addEventListener('resize', updateLayoutListeners, false);

			// Run the initial events when the page first loads.
			await setupItemEvents();

			// Watch for the inventory to be updated.
			if (window.DDC && window.DDC.pubsub) {
				window.DDC.pubsub.subscribe('ws-inv-data/inventory', async () => {
					await setupItemEvents();
				});
			}

			// Watch for the listing view to be toggled.
			window.DDC.InvData.registerToggleListingView(async () => {
				await setupItemEvents();
				reprocessDisplayedItems();
			});

			return true;
		};

		if (window.DDC?.PrivateAPI?.wsInvListingRendered) {
			initializeSRPEvents();
		} else if (window.DDC.pubsub) {
			window.DDC.pubsub.subscribe('ws-inv-listing-update', () => {
				initializeSRPEvents();
			});
		} else {
			window.addEventListener('ws-inv-listing-update', () => {
				initializeSRPEvents();
			});
		}
	} else {
		await invokeInventoryListEventEmitters();
		await invokeInventoryItemEventEmitters();
	}
};

(async () => {
	await isDocumentReady();
	initializeEvents();
})();
