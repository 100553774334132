import { getCookie, setCookie } from '../utils/cookies';
import { getUrlParams } from '../utils/helpers';
import { Loader } from './loader';
import '../../types/init.d'

const loaders = [];
const urlParams = getUrlParams();

/**
 * Infer the file type based on the filename path.
 * 
 * @param { string } src 
 * @returns { 'css' | 'js' | 'unknown' }
 */
const determineFileType = src => {
	if (src.includes('.css') || src.includes('/css')) {
		return 'css';
	}
	if (src.includes('.js') || src.includes('/js')) {
		return 'js';
	}
	return 'unknown';
};

/**
 * Load an asset onto the page.
 * 
 * @param { string | Init } init 
 * @param { string } src 
 * @param { 'css' | 'js' } type 
 * @param { Map<string, string> } attr 
 * @param { boolean } isIntegrationLoad 
 * @returns { Promise<string> }
 */
export const load = async (
	init,
	src,
	type,
	attr = new Map(),
	isIntegrationLoad
) => {
	const fileType = type || determineFileType(src);
	const loader = new Loader(init, src, fileType, attr, isIntegrationLoad);

	if (loaders.some(instance => instance.src === src)) {
		return true;
	}
	loaders.push(loader);
	return loader.load(fileType);
};

/**
 * Load a JS script tag onto the page.
 * 
 * @param { string | Init } init 
 * @param { string } src 
 * @param { Map<string, string> } attr 
 * @param { boolean } isIntegrationLoad 
 * @returns { Promise<string> }
 */
export const loadJS = async (init, src, attr, isIntegrationLoad = false) => {
	return load(init, src, 'js', attr, isIntegrationLoad);
};


/**
 * Load a CSS link tag onto the page.
 * 
 * @param { string | Init } init 
 * @param { string } src 
 * @returns { Promise<string> }
 */
export const loadCSS = async (init, src) => {
	return load(init, src, 'css');
};

/**
 * Invoke all Loaders withing a given scope.
 * 
 * @param { 'all' | 'js' | 'css' } scope Scope of files to load.
 */
export const invokeLoaders = scope => {
	return loaders.forEach(loader => {
		if (!loader.invoked) {
			loader.load(scope);
		}
	});
};

/**
 * Invoke all Loaders.
 */
export const invokeAllLoaders = () => {
	invokeLoaders('all');
};

// For testing
export const test = (init, src) => {
	const id = typeof init === 'string' ? init : init.integrationId;
	if (id && src) {
		setCookie('WebIntegrationAPITestLoader', `${id}|${src}`);
	}
	if (urlParams._integrationMode !== undefined) {
		return load(init, src);
	}
	return false;
};

const cookieValue = getCookie('WebIntegrationAPITestLoader');
if (cookieValue && urlParams._integrationMode !== undefined) {
	const cookie = cookieValue.split('|');
	const id = cookie[0];
	const src = cookie[1];
	load(id, src);
}
