import { DealershipInfoEventEmitter } from './emitters/dealership-info-v1';
import { PageLoadEventEmitter } from './emitters/page-load-v1';
import { VehicleDataUpdatedEmitter } from './emitters/vehicle-events/vehicle-data-updated-v1';
import { VehicleShownEventEmitter } from './emitters/vehicle-events/vehicle-shown-v1';
import {
	populateEditSites,
	remediateStylingIssues
} from '../utils/populate-edit-sites';

import { trackTiming } from '../timings';

const standardEmitters = [
	new PageLoadEventEmitter(),
	new DealershipInfoEventEmitter()
];

const vehicleListEmitters = [new VehicleDataUpdatedEmitter()];

const vehicleItemEmitters = [new VehicleShownEventEmitter()];

const invokeEventEmitters = async (emitters, data) => {
	emitters.forEach(emitter => {
		const start = Date.now();

		try {
			emitter.invoke(data);
			const end = Date.now();
			window.DDC.integrationLog(`Firing event emitter: ${emitter.eventType}`);
			trackTiming('event-emitter', emitter.eventType, end - start);
		} catch (e) {
			const end = Date.now();
			trackTiming('event-emitter-failed', emitter.eventType, end - start);
			window.DDC.integrationLog(
				`Event emitter ${emitter.eventType} failed and took ${
					end - start
				}ms to fail.`,
				e
			);
		}
	});

	return true;
};

export const invokeStandardEventEmitters = async () => {
	await populateEditSites();
	await remediateStylingIssues();
	return invokeEventEmitters(standardEmitters);
};

export const invokeInventoryListEventEmitters = async () => {
	return invokeEventEmitters(vehicleListEmitters);
};

// The expected format of `vehicle` is an object with the vehicle's uuid: `{uuid: "6f0d2ca80a0e0dbd2120e10d85909620"}`
export const invokeInventoryItemEventEmitters = async vehicle => {
	return invokeEventEmitters(vehicleItemEmitters, vehicle);
};
