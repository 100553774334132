import * as T_ERROR from '../globals'

// Handlers
const handleCoxOnly = () => {
	throw new Error('External integrations are disabled due to onlyCoxIntegration mode.')
}

const handleDebugThirdParty = () => {
	throw new Error('This integration is disabled because Third Party Debug is enabled.')
}

const handleDeviceEnablement = () => {
	throw new Error('Integration disabled for target device.')
}

const handleDisableThirdParty = () => {
	throw new Error('Integration disabled due to _onlyDisableThirdParty param.')
}

const handleEnableThirdParty = () => {
	throw new Error('Integration disabled due to _onlyEnableThirdParty param.')
}

const handleInvalidProtocol = () => {
	throw new Error('URL defined does not start with https:// or / and HTTPS is required.')
}

const handleResetCustom = () => {
	throw new Error('Integrations are disabled.')
}

const handleDefault = () => {
	throw new Error('An Error occured validating an Integration')
}

// Builder reducer
/**
 * 
 * @param { string } type 
 * @returns { void }
 */
export const errorBuilder = (
	type
) => {
	switch (type) {
		case T_ERROR.COX_ONLY:				return handleCoxOnly()
		case T_ERROR.DEBUG_THIRD_PARTY:		return handleDebugThirdParty()
		case T_ERROR.DEVICE_ENABLEMENT:		return handleDeviceEnablement()
		case T_ERROR.DISABLE_THIRD_PARTY:	return handleDisableThirdParty()
		case T_ERROR.ENABLE_THIRD_PARTY:	return handleEnableThirdParty()
		case T_ERROR.INVALID_PROTOCOL:		return handleInvalidProtocol()
		case T_ERROR.RESET_CUSTOM:			return handleResetCustom()
		default: return handleDefault();
	}
}