import { Location } from '../../location';
import { getPageMetaData } from '../../utils/get-page-meta-data';

export class PageLinkLocation extends Location {
	constructor(locations) {
		super();
		this.locations = locations;
	}

	getMeta() {
		return getPageMetaData();
	}

	async getElements() {
		return this.locations;
	}
}
