import { trackAPIMethods } from '../tracking';
import { applyButtonOverrides } from './button-markup';

export const create = (init, type, options) => {
	trackAPIMethods(init, { methodType: 'Create', type, ...options });
	if (type === 'button') {
		return applyButtonOverrides(init, document.createElement('a'), options);
	}
	return null;
};
