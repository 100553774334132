import { PageLocation } from './page-location';
import { getVehicleObject } from '../../utils/get-vehicles';
import { hasInventoryData } from '../../utils/helpers';

export class VehicleLocation extends PageLocation {
	async isReady() {
		return hasInventoryData();
	}

	getMeta(elem) {
		return getVehicleObject(elem);
	}
}
