import { getAttributeForVehicles } from './get-vehicles';
import { log } from '../log';

export const unlockPricing = async (init, ids) => {
	if (!window?.DDC?.userProfileController?.saveInstantEpriceVehicle) {
		return false;
	}

	let uuids = ids;

	if (!uuids) {
		uuids = await getAttributeForVehicles(init, 'uuid');
	}

	const saveVehicle = (uuid) => {
		return new Promise((resolve) => {
			try {
				window.DDC.userProfileController.saveInstantEpriceVehicle(null, uuid);
			} catch (error) {
				log(`Failed to save vehicle with UUID ${uuid}:`, error);
			}
			resolve();
		});
	};

	try {
		await Promise.all(uuids.map(uuid => saveVehicle(uuid)));
		return true;
	} catch (error) {
		log('Failed to unlock pricing:', error);
		return false;
	}
};


export const getUnlockedVehicles = async () => {
	const vehicles = window?.DDC?.userProfile?.ePriceVehicles || [];
	return [
		...new Set(
			vehicles.map(item => item?.itemUUID ?? null).filter(uuid => uuid)
		)
	];
};
