import { InvokeOnceEventEmitter } from '../invoke-once-event-emitter';
import { getDealershipData } from '../../utils/get-dealership-data';

export class DealershipInfoEventEmitter extends InvokeOnceEventEmitter {
	constructor() {
		super('dealership-info-v1');
	}

	invokeOnce() {
		this.fireEvent({ payload: getDealershipData() });
	}
}
