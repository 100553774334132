/**
 * Validate debug mode for thirdparty services
 * 
 * Fails if URL param thirdPartyServiceDebug is disabled with debugMode enabled
 * 
 * * `true` === passing
 * * `false` === failing
 * 
 * @param { {} } urlParams 
 * @param { boolean } debugMode 
 * @returns 
 */
export const thirdPartyServiceDebug = (urlParams, debugMode) => {
    if (
        debugMode === true &&
        urlParams.thirdPartyServiceDebug !== 'true'
    ) {
        return false
    }
    return true
}