import { Location } from '../../location';
import { getPageMetaData } from '../../utils/get-page-meta-data';

export class PageLocation extends Location {
	constructor(
		name,
		{
			classes = '',
			elementType = 'div',
			singleLocation = false,
			clearContent = false,
			placement = 'default'
		} = {}
	) {
		super(name);

		this.options = {
			classes,
			elementType,
			singleLocation,
			clearContent,
			placement
		};
	}

	async getConfig() {
		return this.options;
	}

	getMeta() {
		return getPageMetaData();
	}
}
