/**
 * Takes a comma separated key=value pair strings and converts them into a `Map`.
 * This is used to convert the WIS string of attributes set on a URL into iterable values.
 * 
 * ```
 *  // input
 *  'foo=bar,test=1234'
 * 
 *  // output
 *  new Map(
 *      ['foo','bar'],
 *      ['test','1234']
 *  )
 * ```
 * 
 * @param { string } attrMapString
 * @returns { Map<string, string> }
 */
export const loadAttrs = (attrMapString) => {
    // split the key=value pairs into a list
    const mapItems = attrMapString.split(',');

    // reduce over the list into an attribute Map
    const attrMap = mapItems.reduce((itemMap, item) => {
        // split a key=value pair
        const [key, value] = item.split('=');
        // set the new key in the Map
        itemMap.set(key, value);
        
        // return the Map with the new key
        return itemMap

    }, new Map());

    // return the attribute Map
    return attrMap
}