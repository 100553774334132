import { fireEvent } from './index';
import { validateNonZeroLengthString } from '../utils/validator';

const validateEventType = eventType => {
	validateNonZeroLengthString(eventType, 'event type');

	if (!eventType.match(/.*-v\d+/)) {
		throw new Error(
			`Event types must end with a version (like -v1). Got: ${eventType}.`
		);
	}
};

export class EventEmitter {
	constructor(eventType) {
		validateEventType(eventType);
		this.eventType = eventType;

		this.fireEvent = this.fireEvent.bind(this);
		this.invoke = this.invoke.bind(this);
	}

	fireEvent(data) {
		fireEvent({
			type: this.eventType,
			...data
		});
	}

	invoke() {
		throw new Error('Event emitters should override invoke to fire events.');
	}
}
